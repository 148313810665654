  import React, { Fragment } from "react";
  import LandingBanner from "../components/landing_banner/LandingBanner";
  import Card from "../components/UI/Card";
  import WorkCompanion from "../components/work_companion/WorkCompanion";
  import Helmet from 'react-helmet';
  import Data from "../assets/data.json"
  import {it1, it2, it3, it4, it5} from "../assets/images"
  const ITServices = () => {
    return (
      <Fragment>
        <Helmet>
          <title>{Data["ITdata"][0]}</title>
          <meta
            name="description"
            content={Data["metadata"][21]}
          />
        </Helmet>
        <LandingBanner
          title={Data["ITdata"][0]}
          textBold=""
          text={Data["ITdata"][2]}
          class="color-green section_bottom_margin"
          cta_title={Data["footerParagraph"][1]}
          cta_link="www.google.com"
          type="primary"
          headingClass="color-green"
        />

        <section className="section_bottom_margin first_section ml-30 mr-30 d-sm-block ">
          <div className="section_2-3 mr-30 mr-sm-0 mb-sm-3">
            <Card>
              <div className="first_section_text_div">
                <div className="heading_left_horizontal bg-green"></div>

                <h2 className="color-green mb-3 mt-sm-60px">
                {Data["ITdata"][3]}
                </h2>
                <p className="mb-2">
                {Data["ITdata"][4]}
                </p>
              </div>
            </Card>
          </div>
          <div className="section_1-3 ">
            <Card>
              <div className="first_section_illustration_div">
                <img className="img-center w-35" src={it2} />
              </div>
            </Card>
          </div>
        </section>

        <section className="section_bottom_margin d-flex ml-30 mr-30 d-sm-block">
          <div className="section_1-3 mr-30 mr-sm-0">
            <Card>
              <div className="first_section_illustration_div">
                <img className="img-center w-70" src={it3} />
              </div>
            </Card>
          </div>
          <div className="section_2-3 ">
            <Card>
              <div className="first_section_text_div">
                <h2 className="color-green mb-3">{Data["ITdata"][5]}</h2>
                <h4 className="mb-3">
                {Data["ITdata"][6]}
                </h4>
                <p className="mb-2"><b>{Data["ITdata"][7]} :</b> {Data["ITdata"][8]}</p>
                <p className="mb-2"><b>{Data["ITdata"][9]} :</b> {Data["ITdata"][10]}</p>
                <p className="mb-2"><b>{Data["ITdata"][11]} :</b> {Data["ITdata"][12]}</p>
                <p className="mb-2"><b>{Data["ITdata"][13]} :</b> {Data["ITdata"][14]}</p>
                <p className="mb-2"><b>{Data["ITdata"][15]} :</b> {Data["ITdata"][16]}</p>
                <p className="mb-2"><b>{Data["ITdata"][17]} :</b> {Data["ITdata"][18]}</p>
              </div>
            </Card>
          </div>
        </section>
        <section className="section_bottom_margin d-flex ml-30 mr-30">
          <Card extraClasses="d-flex d-sm-block">
            <div className="section_2-3 p-120px p-relative py-sm-2rem px-sm-30">
            <div className="heading_left_vertical bg-green"></div>

              <h2 className="color-green mb-3 mt-sm-130px">{Data["ITdata"][19]}</h2>
              <p className="mb-2"><b>{Data["ITdata"][20]} :</b> {Data["ITdata"][21]}</p>
                <p className="mb-2"><b>{Data["ITdata"][22]} :</b> {Data["ITdata"][23]}</p>
                <p className="mb-2"><b>{Data["ITdata"][24]} :</b> {Data["ITdata"][25]}</p>
                <p className="mb-2"><b>{Data["ITdata"][26]} :</b> {Data["ITdata"][27]}</p>
                <p className="mb-2"><b>{Data["ITdata"][28]} :</b> {Data["ITdata"][29]}</p>
            </div>
            <div className="section_1-3">
              <img className="img-center p-5 w-100" src={it1} />
            </div>
          </Card>
        </section>
        <WorkCompanion />
        <section className="section_bottom_margin d-flex ml-30 mr-30 d-sm-block">
          <div className="section1-2 mr-30 mr-sm-0">
            <Card extraClasses="p-120px py-sm-2rem px-sm-30">
              <h2 className="color-green mb-3">{Data["ITdata"][30]}</h2>
              <p className="mb-2"><b>{Data["ITdata"][31]} :</b> {Data["ITdata"][32]}</p>
                <p className="mb-2"><b>{Data["ITdata"][33]} :</b> {Data["ITdata"][34]}</p>
                <p className="mb-2"><b>{Data["ITdata"][35]} :</b> {Data["ITdata"][36]}</p>
                <p className="mb-2"><b>{Data["ITdata"][37]} :</b> {Data["ITdata"][38]}</p>
            </Card>
          </div>
          <div className="section1-2">
            <Card extraClasses="p-120px p-px">
              <img className="img-center p-1 w-50" src={it4} />
            </Card>
          </div>
        </section>

        <section className="section_bottom_margin d-flex ml-30 mr-30 d-sm-block">
          <div className="section_1-3 mr-30 mr-sm-0 mb-sm-3">
            <Card>
              <div className="first_section_illustration_div">
                <img className="img-center w-50" src={it5} />
              </div>
            </Card>
          </div>
          <div className="section_2-3 ">
            <Card>
              <div className="first_section_text_div">
                <h2 className="color-green mb-3">{Data["ITdata"][39]}</h2>
                <p className="mb-2"><b>{Data["ITdata"][40]} :</b> {Data["ITdata"][41]}</p>
                <p className="mb-2"><b>{Data["ITdata"][42]} :</b> {Data["ITdata"][43]}</p>
                <p className="mb-2"><b>{Data["ITdata"][44]} :</b> {Data["ITdata"][45]}</p>
                <p className="mb-2"><b>{Data["ITdata"][46]} :</b> {Data["ITdata"][47]}</p>
              </div>
            </Card>
          </div>
        </section>
        <section className="section_bottom_margin ml-30 mr-30">
          <div className="text-center ">
            <div className="section1-2 mr-30 ">
               <p><i className="texti" style={{marginLeft:"100px",marginRight:"100px"}}>{Data["ITdata"][49]}</i></p>
            </div>
            
          </div>
        </section>
      </Fragment>
    );
  };

  export default ITServices;
