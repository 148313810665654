  import React from "react";
  import { Fragment } from "react";
  import LandingBanner from "../components/landing_banner/LandingBanner";
  import Card from "../components/UI/Card";
  import WorkCompanion from "../components/work_companion/WorkCompanion";
  import { Swiper, SwiperSlide } from "swiper/react";
  import { Autoplay } from "swiper";
  import "swiper/css";
  import Helmet from "react-helmet";
  import Data from "../assets/data.json";
  import {Web1, Web2,Web3, Web4, Web5} from "../assets/images"

  const WebDevelopment = () => {
    return (
      <Fragment>
        <Helmet>
          <title>{Data["webdevelopmentdata"][0]}</title>
          <meta
            name="description"
            content={Data["metadata"][35]}
          />
        </Helmet>
        <LandingBanner
          title={Data["webdevelopmentdata"][0]}
          textBold=""
          text=
          {Data["webdevelopmentdata"][2]}
          
          class="color-yellow section_bottom_margin"
          cta_title={Data["footerParagraph"][1]}
          cta_link="www.google.com"
          headingClass="color-yellow"
        />

        <section className="section_bottom_margin first_section ml-30 mr-30 d-sm-block">
          <div className="section_2-3 mr-30 mr-sm-0 mb-sm-3">
            <Card>
              <div className="first_section_text_div">
                <div className=" bg-yellow heading_left_horizontal"></div>
                <h2 className="color-yellow  mb-3 mt-sm-60px">{Data["webdevelopmentdata"][3]}</h2>
                <p className="mb-2 para_font_400_16px line-height-30">
                {Data["webdevelopmentdata"][4]}
                </p>
                
              </div>
            </Card>
          </div>
          <div className="section_1-3 ">
            <Card>
              <div className="first_section_illustration_div">
                <img className="img-center w-70" src={Web1} alt="img_logo" />
              </div>
            </Card>
          </div>
        </section>
        <section className="section_bottom_margin ml-30 mr-30">
          <Card>
            <div className="d-grid-50-50">
              <div className="m-2 p-2 bg-yellow border-radius-14px p-relative mx-sm-2rem">
                <img
                  className="w-80 d-block img-center box-shadow-img"
                  src={Web2}
                  alt="img_logo"
                />
              </div>
              <div className="  p-120px py-sm-2rem px-sm-30">
                <h2 className="color-yellow mb-3">{Data["webdevelopmentdata"][5]}</h2>
                <p className="mb-3  para_font_400_16px">
                {Data["webdevelopmentdata"][6]}
                </p>
                <p className="mb-2 para_font_400_16px"><b>{Data["webdevelopmentdata"][7]}</b> {Data["webdevelopmentdata"][8]}</p>
                <p className="mb-2 para_font_400_16px"><b>{Data["webdevelopmentdata"][9]}</b> {Data["webdevelopmentdata"][10]}</p>
                <p className="mb-2 para_font_400_16px"><b>{Data["webdevelopmentdata"][11]}</b> {Data["webdevelopmentdata"][12]}</p>
                <p className="mb-2 para_font_400_16px"><b>{Data["webdevelopmentdata"][13]}</b> {Data["webdevelopmentdata"][14]}</p>
                <p className="mb-2 para_font_400_16px"><b>{Data["webdevelopmentdata"][15]}</b> {Data["webdevelopmentdata"][16]}</p>
                <p className=" para_font_400_16px"><b>{Data["webdevelopmentdata"][17]}</b> {Data["webdevelopmentdata"][18]}</p>
              </div>
            </div>
          </Card>
        </section>
        <section className="section_bottom_margin ml-30 mr-30 ml-30 mr-30">
          <div className="d-grid-50-50 ">
            <div className="section1-2 mr-30 mr-sm-0">
              <Card>
                <div className="p-120px px-sm-30 py-sm-2rem">
                  <h2 className="color-yellow mb-3">{Data["webdevelopmentdata"][19]}</h2>
                  <p className="mb-2 para_font_400_16px"><b>{Data["webdevelopmentdata"][20]}</b> {Data["webdevelopmentdata"][21]}</p>
                <p className="mb-2 para_font_400_16px"><b>{Data["webdevelopmentdata"][22]}</b> {Data["webdevelopmentdata"][23]}</p>
                <p className="mb-2 para_font_400_16px"><b>{Data["webdevelopmentdata"][24]}</b> {Data["webdevelopmentdata"][25]}</p>
                <p className="para_font_400_16px"><b>{Data["webdevelopmentdata"][26]}</b> {Data["webdevelopmentdata"][27]}</p>
                {/* <p><b>{Data["webdevelopmentdata"][28]}</b> {Data["webdevelopmentdata"][29]}</p><br></br> */}
                {/* <p><b>{Data["webdevelopmentdata"][30]}</b> {Data["webdevelopmentdata"][31]}</p> */}
                                  
                                  
                                  
                  </div>
              </Card>
            </div>
            <Card extraClasses="p-50px">
              <div>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  loop={true}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                >
                  <SwiperSlide>
                    <img className="w-100 m-auto d-block" src={Web3} alt="img_logo" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      className="w-80 m-auto d-block box-shadow-img"
                      src={Web4}
                      alt="img_logo"
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </Card>
          </div>
        </section>

        <section className="section_bottom_margin ml-30 mr-30">
          <Card>
            <div className="employee_onboarding__div d-grid-50-50 p-120px px-sm-30 py-sm-2rem">
              <div className="">
                <div className="m-auto">
                  <h2 className="color-yellow mb-3">{Data["webdevelopmentdata"][32]}</h2>
                  <p className="mb-2 para_font_400_16px"><b>{Data["webdevelopmentdata"][33]}</b> {Data["webdevelopmentdata"][34]}</p>
                <p className="mb-2 para_font_400_16px"><b>{Data["webdevelopmentdata"][35]}</b> {Data["webdevelopmentdata"][36]}</p>
                <p className="mb-2 para_font_400_16px"><b>{Data["webdevelopmentdata"][37]}</b> {Data["webdevelopmentdata"][38]}</p>
                <p className="mb-2 para_font_400_16px"><b>{Data["webdevelopmentdata"][39]}</b> {Data["webdevelopmentdata"][40]}</p>
                <p className=" para_font_400_16px mb-sm-60"><b>{Data["webdevelopmentdata"][41]}</b> {Data["webdevelopmentdata"][42]}</p>
                </div>
              </div>
              <div className="p-relative">
                <img
                  className="w-80 img-center box-shadow-img "
                  src={Web5}
                  alt="img_logo"
                />
              </div>
            </div>
          </Card>
        </section>

        {/* <section className="section_bottom_margin ml-30 mr-30">
          <Card>
            <div className="d-grid-40-60 align-items-center p-relative">
              <div className="rectangle_type_3"></div>
              <div className="p-relative">
                <img
                  className="ml-auto d-block p-5 w-70 mx-sm-2rem w-sm-85"
                  src={PolicyManangement}
                />
              </div>
              <div className="w-80 m-auto px-sm-30 py-sm-2rem w-sm-100">
                <h2 className="color-yellow mb-3">{Data["webdevelopmentdata"][43]}</h2>
                <p className="mb-2 para_font_400_16px"><b>{Data["webdevelopmentdata"][44]}</b> {Data["webdevelopmentdata"][45]}</p>
                <p className="mb-2 para_font_400_16px"><b>{Data["webdevelopmentdata"][46]}</b> {Data["webdevelopmentdata"][47]}</p>
                <p className="mb-2 para_font_400_16px"><b>{Data["webdevelopmentdata"][48]}</b> {Data["webdevelopmentdata"][49]}</p>
                <p className="mb-2 para_font_400_16px"><b>{Data["webdevelopmentdata"][50]}</b> {Data["webdevelopmentdata"][51]}</p>
              </div>
            </div>
          </Card>
        </section> */}

        <section className="section_bottom_margin ml-30 mr-30">
          <div className="text-center ">
            <div className="section1-2 mr-30 ">
               <p ><i className="texti" style={{marginLeft:"100px",marginRight:"100px"} }>{Data["webdevelopmentdata"][53]}</i></p>
            </div>
            
          </div>
        </section>

        {/* ........ Work Companion Component Start ..............  */}

        <WorkCompanion />

        {/* ........ Work Companion Component End ..............  */}

        {/* <section className="section_bottom_margin ml-30 mr-30">
          <div className="d-flex ">
            <div className="section1-2 mr-30 ">
              <Card>
                <div className="h-100">
                  <img className="img-center box-shadow-img w-80" src={AccessControls} />
                </div>
              </Card>
            </div>
            <div className="section1-2 ">
              <Card>
                <div className="p-120px">
                  <h2 className="color-yellow mb-3">ACCESS CONTROLS</h2>
                  <h4 className="mb-3">
                    Role-Specific Configurable Framework For Unique <br></br>
                    Experience
                  </h4>
                  <p className="mb-2">
                    Deciding an employee’s role in a project or a task and then
                    implementing it can take up a lot of time. 
                    extraordinary permission module framework enables you to
                    configure access control as unique as your organisation. It
                    allows you to assign permissions to a specific individual and
                    save permissions set by roles.
                  </p>
                  <p>
                    Customise who can see what with multi-level rights and
                    permission system.
                  </p>
                </div>
              </Card>
            </div>
          </div>
        </section> */}

        {/* <section className="section_bottom_margin d-grid-33-66 ml-30 mr-30">
          <div className="">
            <Card>
              <div className="p-relative">
              <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  loop={true}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                >
                  <SwiperSlide>
                    <img className="w-50 m-auto d-block pt-5" src={WorkforceDirectory} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      className="w-80 m-auto d-block box-shadow-img"
                      src={workforcedirectoryweb}
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </Card>
          </div>

          <div className="">
            <Card>
              <div className="first_section_text_div">
                <h2 className="color-yellow mb-3">WORKFORCE DIRECTORY</h2>
                <h4 className="mb-3">
                  Readily Monitor, Manage And Retrieve Employee <br></br>
                  Information
                </h4>
                <p className="mb-2">
                  Storing, updating and accessing a plethora of employee databases
                  is a tedious and time taking process. With , the same task
                  can be done in a matter of minutes and a few clicks. By default,
                  the suite segregates employee data into personal, contact,
                  professional, payroll info, and employee documents.
                  Customisation allows the option of adding new sections and
                  fields as and when needed.
                </p>
                <p>
                  Automating mundane HR activities leaves more time and energy to
                  put effective efforts toward organisational goals.
                </p>
              </div>
            </Card>
          </div>
        </section>

        <section className="section_bottom_margin ml-30 mr-30">
          <div className="d-flex ">
            <div className="section1-2 mr-30">
              <Card>
                <div className="p-120px">
                  <h2 className="color-yellow mb-3">HR HANDBOOK</h2>
                  <h4 className="mb-3">
                    Publish Company-Wide Docs For Greater <br></br>
                    Transparency
                  </h4>
                  <p className="mb-2">
                    Verbally communicating or printing policies each time there is
                    a requirement causes inefficiencies.  with its HR handbook
                    feature has the ability to publish the company policies and
                    receive e-acceptance from the employees on the go.
                  </p>
                  <p>
                    Make room for greater transparency within your organisation
                    with the single platform.
                  </p>
                </div>
              </Card>
            </div>
            <div className="section1-2  ">
              <Card>
                <div className="h-100">
                  <img className="img-center" src={firstIllustation} />
                </div>
              </Card>
            </div>
          </div>
        </section> */}
      </Fragment>
    );
  };

  export default WebDevelopment;
