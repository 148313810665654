// import logo from './logo.svg';
import { Fragment, useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Footer from "./components/footer/Footer";
import WebDevelopment from "./pages/WebDevelopment";
import HomePage from "./pages/HomePage";
import AppDevelopment from "./pages/AppDevelopment";
import Navbar from "./components/navbar/Navbar";
import Softwaresolutions from "./pages/Softwaresolutions";
import BrandingStrategies from "./pages/BrandingStrategies";
import ITServices from "./pages/ITServices";
import Blog from "./pages/Blog";
import Pricing from "./pages/Pricing";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import LandingNav from "./components/landing_nav/LandingNav";
import Landing from "./pages/Landing";
import Page404 from "./pages/404";
import Privacypolicy from "./pages/Privacypolicy";
import TermsofService from "./pages/TermsofService";
import RefundPolicy from "./pages/RefundPolicy";
import CancellationPolicy from "./pages/CancellationPolicy";
import FAQs from "./pages/FAQs";
import Gallary from "./pages/Gallary";
import Sco from "./pages/Sco";
import Customers from "./pages/Customers";
import Careers from "./pages/Careers";
import Mission from "./pages/Mission";
import Blog1 from "./pages/Blog1";
import Blog2 from "./pages/BLog2";
import Blog3 from "./pages/Blog3";
import Blog4 from "./pages/Blog4";
import Blog5 from "./pages/Blog5";
import Blog6 from "./pages/Blog6";
function App() {
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <Fragment>
      {location.pathname === '/Landing' ? <LandingNav/> : <Navbar /> }
      <Routes>
        <Route path="/Landing" element={<Landing />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/appdevelopment" element={<AppDevelopment />} />
        <Route path="/webdevelopment" element={<WebDevelopment />} />
        <Route path="/softwaresolutions" element={<Softwaresolutions />} />
        <Route path="/brandingstrategies" element={<BrandingStrategies />} />
        <Route path="/itservices" element={<ITServices />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/campaign" element={<Landing />} />
        <Route path="/privacypolicy" element={<Privacypolicy />} />
        <Route path="/termsofservice" element={<TermsofService />} />
        <Route path="/refundpolicy" element={<RefundPolicy />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/cancellationpolicy" element={<CancellationPolicy />} />
        <Route path="/gallary" element={<Gallary />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/mission" element={<Mission />} />
        <Route path="/sco" element={<Sco />} />
        <Route path="/blogs/Foundation" element={<Blog1 />} />
        <Route path="/blogs/UIKit" element={<Blog2 />} />
        <Route path="/blogs/Material-UI" element={<Blog3 />} />
        <Route path="/blogs/Evolution" element={<Blog4 />} />
        <Route path="/blogs/Fundamentals" element={<Blog5 />} />
        <Route path="/blogs/SoftwareDevelopment" element={<Blog6 />} />

        <Route path="/*" element={<Page404 />} />
      </Routes>
      {location.pathname === '/' ? <Footer /> : <Footer /> }

    </Fragment>
  );
}

export default App;
