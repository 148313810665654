import React, { Fragment } from "react";
import Helmet from 'react-helmet';
import Data from "../assets/data.json";
import {blog6 } from "../assets/images";
const Blog6 = () => {
return (
    <Fragment>
    <Helmet>
        <title>
        {Data["blog6"][0]}
        </title>
        <meta
        name="description"
        content={Data["blog6"][1]}
        />
    </Helmet>
    <section className="bg-white pricing_banner_section policy">
                <div className="princing_banner_text text-center">
                    <h1 className="fontsizeh1">
                        {Data["blog6"][0]}
                    </h1>
                </div>
            </section>
            <section className="section_bottom_margin justify-content-center align-items-stretch p-0px" style={{ padding: "100px" }}>
                <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
                   
                   <div className="text-center" style={{marginBottom:"30px"}}>
                   <img src={blog6} alt="blog6" className="w-50D" style={{borderRadius:"30px"}}></img>
                   </div>
                    <h4 className="text-primary mb-3">{Data["blog6"][2]}</h4>
                    <p className="mb-3">{Data["blog6"][3]}</p>
                    <h4 className="text-primary mb-3">{Data["blog6"][4]}</h4>
                    <p className="mb-3">{Data["blog6"][5]}</p>
                    <h4 className="text-primary mb-3">{Data["blog6"][6]}</h4>
                    <p className="mb-3">{Data["blog6"][7]}</p>
                    <h4 className="text-primary mb-3">{Data["blog6"][8]}</h4>
                    <p className="mb-3">{Data["blog6"][9]}</p>
                    <h4 className="text-primary mb-3">{Data["blog6"][10]}</h4>
                    <p className="mb-3">{Data["blog6"][11]}</p>
                    <h4 className="text-primary mb-3">{Data["blog6"][12]}</h4>
                    <p className="mb-3">{Data["blog6"][13]}</p>
                    <h4 className="text-primary mb-3">{Data["blog6"][14]}</h4>
                    <p className="mb-3">{Data["blog6"][15]}</p>
                    
                    <h4 className="text-primary mb-3">{Data["blog6"][16]}</h4>
                    <p className="mb-3">{Data["blog6"][17]}</p>
                    <h4 className="text-primary mb-3">{Data["blog6"][18]}</h4>
                    <p className="mb-3">{Data["blog6"][19]}</p>
                   
                </section>
            </section>
   
    </Fragment>
);
};

export default Blog6;
