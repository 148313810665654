import React, { useState } from 'react'
import classes from './FaqLanding.module.css'
import  {AiOutlineDown, AiOutlineUp} from 'react-icons/ai'
import {RiArrowDownSLine, RiArrowUpSLine} from 'react-icons/ri'
const FaqLanding = (props) => {
    const [isOpen, setIsOpen] = useState(0);

    const showAnswer = () =>{
        setIsOpen(!isOpen);
    }
  return (
    <div className={classes.faq_div + ' ' + props.extraClasses} onClick={showAnswer}>
        <h3 className='p-relative mb-3'>{props.title}  <span className={classes.faq_icon}> {isOpen ?  <RiArrowUpSLine/> : <RiArrowDownSLine/> }</span> </h3>
        <p className={'pb-3 ' + (isOpen? ' ' : 'd-none')}>{props.detials}</p>
    </div>
  )
}

export default FaqLanding