import React, { Fragment } from "react";
import PricingCard from "../components/pricing_card/PricingCard";
import Button from "../components/UI/Button";
import Helmet from 'react-helmet';

const Pricing = () => {
  return (
    <Fragment>
      <Helmet>
        <title></title>
        <meta
          name="description"
          content=""
        />
      </Helmet>
      <section className="bg-white pricing_banner_section">
        <div className="princing_banner_text text-center">
            <h1>Easily organize your work. Start free.</h1>
            <p>Access Asana’s features. No credit card required.</p>
            <Button title="Get Started" type="primary"/>
        </div>  
      </section>
      <section className="d-flex px-120px  section_bottom_margin pt-60px justify-content-center align-items-stretch">
        <PricingCard
          badge="Most Popular"
          extraClasses="mr-30"
          title="Basic"
          description="For teams that need to create project plans with confidence."
          pricing="US $0"
          buttonTitle="Get Started"
          features={[
            "Gps attendance",
            "Basic Payroll",
            "Mobile App",
            "Loans and salary advances",
            "Smart alert and reports",
            "Remote/Hybrid work",
            "Dynamic Work Shift",
            "Task Management",
            "Employee self service",
            "Core HR",
            "Employee engagement",
            "Real Time analytics",
            "Unlimited access to Data",
          ]}
        />
        <PricingCard
          extraClasses="mr-30"
          title="Business"
          description="For teams that need to create project plans with confidence."
          pricing="US $0"
          buttonTitle="Get Started"
          features={[
            "Payroll Managment",
            "Attendance Monitoring",
            "Gps Attendance",
            "Becon attendance",
            "Mobile App",
            "Custom Roles and permissions",
            "Loans and salary advances",
            "Multiple legal entities",
            "Smart alert and reports",
            "Remote/Hybrid working",
            "Dynamic work shift",
            "Employee onboarding",
            "Task management",
            "Employee self service",
            "Core HR",
            "Bulk Upload options",
            "Employee engagement",
            "Real-time analytics",
            "Unlimited access to Data",
          ]}
        />
        <PricingCard
          extraClasses="mr-30"
          title="Business"
          description="For teams that need to create project plans with confidence."
          pricing="US $0"
          buttonTitle="Get Started"
          features={[
            "Payroll Managment",
            "Attendance Monitoring",
            "Gps Attendance",
            "Becon attendance",
            "Mobile App",
            "Custom Roles and permissions",
            "Loans and salary advances",
            "Multiple legal entities",
            "Smart alert and reports",
            "Remote/Hybrid working",
            "Dynamic work shift",
            "Employee onboarding",
            "Task management",
            "Employee self service",
            "Core HR",
            "Bulk Upload options",
            "Employee engagement",
            "Real-time analytics",
            "Unlimited access to Data",
          ]}
        />
      </section>
    </Fragment>
  );
};

export default Pricing;
