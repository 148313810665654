import React from "react";
import Button from "../UI/Button";
import classes from "./LandingBanner.module.css";
const LandingBanner = (props) => {
  var sectionClasses = classes.landing_banner + " " + props.class;
  var headingClasses = classes.landing_banner_h1 + " " + props.headingClass;
  return (
    <section className={sectionClasses}>
      <div className="max-w-1600px">
        <h1 className={headingClasses}>{props.title} </h1>
        <p className={classes.landing_banner_text_bold}>{props.textBold}</p>
        <p className={classes.landing_banner_text}>{props.text}</p>
        <Button title={props.cta_title} link="/contact-us" type="primary" />
      </div>
    </section>
  );
};

export default LandingBanner;
