import React from "react";
import { Fragment } from "react";
import LandingBanner from "../components/landing_banner/LandingBanner";
import Card from "../components/UI/Card";
import WorkCompanion from "../components/work_companion/WorkCompanion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import Helmet from "react-helmet";
import Data from "../assets/data.json"
import {sco1, sco2, sco3, sco4, sco5} from "../assets/images"

const Sco = () => {
  return (
    <Fragment>
      <Helmet>
        <title>{Data["Scodata"][0]}</title>
        <meta
          name="description"
          content={Data["metadata"][29]}
        />
      </Helmet>
      <LandingBanner
        title={Data["Scodata"][0]}
        textBold=""
        text=
        {Data["Scodata"][2]}
        
        class="color-yellow section_bottom_margin"
        cta_title={Data["footerParagraph"][1]}
        cta_link="www.google.com"
        headingClass="color-yellow"
      />

      <section className="section_bottom_margin first_section ml-30 mr-30 d-sm-block">
        <div className="section_2-3 mr-30 mr-sm-0 mb-sm-3">
          <Card>
            <div className="first_section_text_div">
              <div className=" bg-yellow heading_left_horizontal"></div>
              <h2 className="color-yellow mt-sm-60px  mb-3">{Data["Scodata"][3]}</h2>
              <p className="mb-3">
              {Data["Scodata"][4]}
              </p>
            </div>
          </Card>
        </div>
        <div className="section_1-3 ">
          <Card>
            <div className="first_section_illustration_div">
              <img className="img-center w-80 border-radius-20" src={sco2}  alt="img_logo" />
            </div>
          </Card>
        </div>
      </section>
      <section className="section_bottom_margin ml-30 mr-30">
        <Card>
          <div className="d-grid-50-50">
            <div className="m-2 p-2 bg-yellow border-radius-14px p-relative mx-sm-2rem">
              <img
                className="w-90 d-block img-center box-shadow-img border-radius-20"
                src={sco1}
                alt="img_logo"
              />
            </div>
            <div className=" p-120px py-sm-2rem px-sm-30">
              <h2 className="color-yellow mb-3">{Data["Scodata"][5]}</h2>
              <p className="mb-3">
              {Data["Scodata"][6]}
              </p>
              <p  className="mb-2 para_font_400_16px"><b>{Data["Scodata"][7]}</b> {Data["Scodata"][8]}</p>
              <p  className="mb-2 para_font_400_16px"><b>{Data["Scodata"][9]}</b> {Data["Scodata"][10]}</p>
              <p  className="mb-2 para_font_400_16px"><b>{Data["Scodata"][11]}</b> {Data["Scodata"][12]}</p>
              <p  className="mb-2 para_font_400_16px"><b>{Data["Scodata"][13]}</b> {Data["Scodata"][14]}</p>
              <p  className="mb-2 para_font_400_16px"><b>{Data["Scodata"][15]}</b> {Data["Scodata"][16]}</p>
              <p  className="mb-2 para_font_400_16px"><b>{Data["Scodata"][17]}</b> {Data["Scodata"][18]}</p>
            </div>
          </div>
        </Card>
      </section>
      <section className="section_bottom_margin ml-30 mr-30 ml-30 mr-30">
        <div className="d-grid-50-50 ">
          <div className="section1-2 mr-30 mr-sm-0">
            <Card>
              <div className="p-120px px-sm-30 py-sm-2rem">
                <h2 className="color-yellow mb-3">{Data["Scodata"][19]}</h2>
                <p  className="mb-2 para_font_400_16px"><b>{Data["Scodata"][20]}</b> {Data["Scodata"][21]}</p>
              <p  className="mb-2 para_font_400_16px"><b>{Data["Scodata"][22]}</b> {Data["Scodata"][23]}</p>
              <p  className="mb-2 para_font_400_16px"><b>{Data["Scodata"][24]}</b> {Data["Scodata"][25]}</p>
              <p  className="para_font_400_16px"><b>{Data["Scodata"][26]}</b> {Data["Scodata"][27]}</p>
              {/* <p><b>{Data["Scodata"][28]}</b> {Data["Scodata"][29]}</p><br></br> */}
              {/* <p><b>{Data["Scodata"][30]}</b> {Data["Scodata"][31]}</p> */}
                                
                                
                                
                </div>
            </Card>
          </div>
          <Card extraClasses="p-50px">
            <div>
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
              >
                <SwiperSlide>
                  <img className="w-50 m-auto d-block border-radius-20" src={sco3} alt="img_logo" />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="w-80 m-auto d-block"
                    src={sco5}
                    alt="img_logo"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </Card>
        </div>
      </section>

      <section className="section_bottom_margin ml-30 mr-30">
        <Card>
          <div className="employee_onboarding__div d-grid-50-50 p-120px px-sm-30 py-sm-2rem">
            <div className="">
              <div className="m-auto">
                <h2 className="color-yellow mb-3">{Data["Scodata"][32]}</h2>
                <p  className="mb-2 para_font_400_16px"><b>{Data["Scodata"][33]}</b> {Data["Scodata"][34]}</p>
              <p  className="mb-2 para_font_400_16px"><b>{Data["Scodata"][35]}</b> {Data["Scodata"][36]}</p>
              <p  className="mb-2 para_font_400_16px"><b>{Data["Scodata"][37]}</b> {Data["Scodata"][38]}</p>
              <p className="para_font_400_16px mb-sm-60"><b>{Data["Scodata"][39]}</b> {Data["Scodata"][40]}</p>
              </div>
            </div>
            <div className="p-relative">
              <img
                className="w-80 img-center box-shadow-img border-radius-20"
                src={sco4}
                alt="img_logo"
              />
            </div>
          </div>
        </Card>
      </section>

      {/* <section className="section_bottom_margin ml-30 mr-30">
        <Card>
          <div className="d-grid-40-60 align-items-center p-relative">
            <div className="rectangle_type_3"></div>
            <div className="p-relative">
              <img
                className="ml-auto d-block p-5 w-70"
                src={PolicyManangement}
              />
            </div>
            <div className="w-80 m-auto">
              <h2 className="color-yellow mb-3">{Data["Scodata"][41]}</h2>
              <p><b>{Data["Scodata"][42]}</b> {Data["Scodata"][43]}</p><br></br>
              <p><b>{Data["Scodata"][44]}</b> {Data["Scodata"][45]}</p><br></br>
              <p><b>{Data["Scodata"][46]}</b> {Data["Scodata"][47]}</p><br></br>
              <p><b>{Data["Scodata"][48]}</b> {Data["Scodata"][49]}</p>
            </div>
          </div>
        </Card>
      </section> */}


      <section className="section_bottom_margin ml-30 mr-30">
          <div className="text-center ">
            <div className="section1-2 mr-30 ">
               <p><i className="texti" style={{marginLeft:"100px",marginRight:"100px"}}>{Data["Scodata"][51]}</i></p>
            </div>
            
          </div>
        </section>
      {/* ........ Work Companion Component Start ..............  */}

      <WorkCompanion />

      {/* ........ Work Companion Component End ..............  */}

      {/* <section className="section_bottom_margin ml-30 mr-30">
        <div className="d-flex ">
          <div className="section1-2 mr-30 ">
            <Card>
              <div className="h-100">
                <img className="img-center box-shadow-img w-80" src={AccessControls} />
              </div>
            </Card>
          </div>
          <div className="section1-2 ">
            <Card>
              <div className="p-120px">
                <h2 className="color-yellow mb-3">ACCESS CONTROLS</h2>
                <h4 className="mb-3">
                  Role-Specific Configurable Framework For Unique <br></br>
                  Experience
                </h4>
                <p className="mb-2">
                  Deciding an employee’s role in a project or a task and then
                  implementing it can take up a lot of time. 
                  extraordinary permission module framework enables you to
                  configure access control as unique as your organisation. It
                  allows you to assign permissions to a specific individual and
                  save permissions set by roles.
                </p>
                <p>
                  Customise who can see what with multi-level rights and
                  permission system.
                </p>
              </div>
            </Card>
          </div>
        </div>
      </section>

      <section className="section_bottom_margin d-grid-33-66 ml-30 mr-30">
        <div className="">
          <Card>
            <div className="p-relative">
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
              >
                <SwiperSlide>
                  <img className="w-50 m-auto d-block pt-5" src={WorkforceDirectory} />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="w-80 m-auto d-block box-shadow-img"
                    src={workforcedirectoryweb}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </Card>
        </div>

        <div className="">
          <Card>
            <div className="first_section_text_div">
              <h2 className="color-yellow mb-3">WORKFORCE DIRECTORY</h2>
              <h4 className="mb-3">
                Readily Monitor, Manage And Retrieve Employee <br></br>
                Information
              </h4>
              <p className="mb-2">
                Storing, updating and accessing a plethora of employee databases
                is a tedious and time taking process. With, the same task
                can be done in a matter of minutes and a few clicks. By default,
                the suite segregates employee data into personal, contact,
                professional, payroll info, and employee documents.
                Customisation allows the option of adding new sections and
                fields as and when needed.
              </p>
              <p>
                Automating mundane HR activities leaves more time and energy to
                put effective efforts toward organisational goals.
              </p>
            </div>
          </Card>
        </div>
      </section>

      <section className="section_bottom_margin ml-30 mr-30">
        <div className="d-flex ">
          <div className="section1-2 mr-30">
            <Card>
              <div className="p-120px">
                <h2 className="color-yellow mb-3">HR HANDBOOK</h2>
                <h4 className="mb-3">
                  Publish Company-Wide Docs For Greater <br></br>
                  Transparency
                </h4>
                <p className="mb-2">
                  Verbally communicating or printing policies each time there is
                  a requirement causes inefficiencies.  with its HR handbook
                  feature has the ability to publish the company policies and
                  receive e-acceptance from the employees on the go.
                </p>
                <p>
                  Make room for greater transparency within your organisation
                  with the single platform.
                </p>
              </div>
            </Card>
          </div>
          <div className="section1-2  ">
            <Card>
              <div className="h-100">
                <img className="img-center" src={firstIllustation} />
              </div>
            </Card>
          </div>
        </div>
      </section> */}
    </Fragment>
  );
};

export default Sco;
