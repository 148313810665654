import React, { Fragment } from "react";
import Helmet from 'react-helmet';
import Data from "../assets/data.json";
import { blog1} from "../assets/images";
const Blog1 = () => {
return (
    <Fragment>
    <Helmet>
        <title>
        {Data["blog1"][0]}
        </title>
        <meta
        name="description"
        content={Data["blog1"][1]}
        />
    </Helmet>
    <section className="bg-white pricing_banner_section policy">
                <div className="princing_banner_text text-center">
                    <h1 className="fontsizeh1">
                        {Data["blog1"][0]}
                    </h1>
                </div>
            </section>
            <section className="section_bottom_margin justify-content-center align-items-stretch p-0px" style={{ padding: "100px" }}>
                <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
                   
                   <div className="text-center" style={{marginBottom:"30px"}}>
                   <img src={blog1} className="w-50D" style={{borderRadius:"30px"}}></img>
                   </div>
                    <h4 className="text-primary mb-3">{Data["blog1"][2]}</h4>
                    <p className="mb-3">{Data["blog1"][3]}</p>
                    <h4 className="text-primary mb-3">{Data["blog1"][4]}</h4>
                    <p className="mb-3">{Data["blog1"][5]}</p>
                    <h4 className="text-primary mb-3">{Data["blog1"][6]}</h4>
                    <p className="mb-3">{Data["blog1"][7]}</p>
                    <h4 className="text-primary mb-3">{Data["blog1"][8]}</h4>
                    <p className="mb-3">{Data["blog1"][9]}</p>
                    <h4 className="text-primary mb-3">{Data["blog1"][10]}</h4>
                    <p className="mb-3">{Data["blog1"][11]}</p>
                    <h4 className="text-primary mb-3">{Data["blog1"][12]}</h4>
                    <p className="mb-3">{Data["blog1"][13]}</p>
                    <h4 className="text-primary mb-3">{Data["blog1"][14]}</h4>
                    <p className="mb-3">{Data["blog1"][15]}</p>
                    
                   
                </section>
            </section>
   
    </Fragment>
);
};

export default Blog1;
