  import React, { Fragment,useState } from "react";
  import Button from "../components/UI/Button";
  import Card from "../components/UI/Card";
  import FaqLanding from "../components/faq_landing/FaqLanding";
  
  import { Helmet } from "react-helmet";
  import Data from "../assets/data.json";

  import * as XLSX from 'xlsx';

  const Landing = () => {
    const [formData, setFormData] = useState({
      email: '',
      designation: '',
      number: '',
      company_name: '',
    });

    // const handleInputChange = (e) => {
    //   const { name, value } = e.target;
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     [name]: value,
    //   }));
    // };
  
    const handleFormSubmit = async (event) => {
      event.preventDefault();
  
      // Check the ReCAPTCHA response
      // const captchaValue = captchaRef.current.getValue();
      // if (!captchaValue) {
      //   alert('Please complete the ReCAPTCHA verification.');
      //   return;
      // }
  
      // Convert form data to JSON
      const jsonData = formData;
     
      // Create a new workbook
      const wb = XLSX.utils.book_new();
  
      // // Add a worksheet to the workbook
      const ws = XLSX.utils.json_to_sheet([jsonData]);
  
      // // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      // // Save the workbook as an Excel file
      XLSX.writeFile(wb, 'salesFormData.xlsx');
      window.location.reload(false);
    };
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    return (
      <Fragment>
        <Helmet>
          <title>
          Venkat Technologies
          </title>
          <meta
            name="description"
            content="At Venkat Technologies, we're dedicated to transforming your digital aspirations into a tangible reality. We are a team of tech enthusiasts, designers, and strategists committed to delivering innovative solutions that empower businesses and individuals in this rapidly evolving digital landscape."
          />
        </Helmet>
        {/* <section className="section_bottom_margin  ml-30 mr-30" style={{marginTop:"30px"}}>
        <Card>
            <Card extraClasses="p-80px mb-5 px-sm-30 py-sm-2rem" id="contactSalesCard">
              <h2>Reach out to us</h2>
              <form id="contact_sales_form" 
              onSubmit={handleFormSubmit}
              >
                <div className="d-grid-50-50 mb-5 mb-sm-0">
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email Address"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  <input
                    type="text"
                    name="designation"
                    placeholder="Designation"
                    value={formData.designation}
          onChange={handleInputChange}
                  />
                </div>
                <div className="d-grid-50-50 mb-5 mb-sm-0">
                  <input
                    type="number"
                    name="number"
                    placeholder="Your Phone Number"
                    value={formData.number}
                    onChange={handleInputChange}
                  
                  />
                  <input
                    type="text"
                    name="company_name"
                    placeholder="Company Name"
                    value={formData.company_name}
                    onChange={handleInputChange}
                  />
                </div>
                <ReCAPTCHA
                  sitekey="6LcU7uQhAAAAAIbb9zGjOKNsWAzMZ5_WcdoBTmwQ"
                  ref={captchaRef}
                />
                <Button type="primary" title="Submit"/>
              </form>
            </Card>
           </Card>
          
      </section> */}
        <section className="section_bottom_margin">
          <div className="landing_page_first_grid">
            <div className="bg-primary p-relative">
              <div className="landing_page_first__text">
                <h1 className="mb-2">
                  {Data["Landing"][0]}
                </h1>
                <p>
                {Data["Landing"][1]}
                </p>
              </div>
            </div>
            <div className="contact-form">
              <form id="contact-form">
                <div className="landing_page_first_form">
                  <div>
                    <input
                      name="work_email"
                      type="email"
                      className=""
                      placeholder="Work Email"
                      required
                    />
                    <input
                      name="designation"
                      className=""
                      placeholder="Designation"
                      required
                    />
                  </div>
                  <div>
                    <input
                      name="phone"
                      type="number"
                      className=""
                      placeholder="Your Phone Number"
                      required
                      minLength="10"
                      min={1111111111}
                      max={99999999999999}
                      oninvalid="setCustomValidity('Contact should be min. of 10 numbers')"
                    />
                    <input
                      name="company_name"
                      className=""
                      placeholder="Company Name"
                      required
                    />
                  </div>
                  <Button title={Data["footerParagraph"][1]} type="primary" />
                </div>
              </form>
            </div>
          </div>
        </section>
        <section className="section_bottom_margin ml-30 mr-30">
          <div className="d-grid-33-33-33 max-w-1600 text-center">
            <div>
              <h4 className="mb-2">{Data["laddingdata"][0]}</h4>
              <p className="text-center">
              {Data["laddingdata"][1]}
              </p>
            </div>
            <div>
              <h4 className="mb-2">{Data["laddingdata"][2]}</h4>
              <p className="text-center">
              {Data["laddingdata"][3]}
              </p>
            </div>
            <div>
              <h4 className="mb-2">{Data["laddingdata"][4]}</h4>

              <p className="text-center">
              {Data["laddingdata"][5]}
              </p>
            </div>
          </div>
        </section>
        {/* <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
          <Card extraClasses="text-center py-40px px-120px">
            <h2 className="mb-2">Meet Our Work Companions</h2>
            <p className="mb-5">
              It is trusted by fast-growing businesses for managing their
              everday HR functions
            </p>
            <Swiper
              slidesPerView={7}
              spaceBetween={80}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className={"client_logo_slider"}
            >
              <SwiperSlide>
                <img className="w-auto m-auto d-block h-58px" src={ActoFitLogo} />
              </SwiperSlide>
              <SwiperSlide>
                <img className="w-50 m-auto d-block" src={CofeeLogo} />
              </SwiperSlide>
              <SwiperSlide>
                <img className="w-auto m-auto d-block h-58px" src={EdgeWell} />
              </SwiperSlide>
              <SwiperSlide>
                <img className="w-auto m-auto d-block h-28px" src={Femella} />
              </SwiperSlide>
              <SwiperSlide>
                <img className="w-auto m-auto d-block h-28px" src={logo_new} />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="w-auto m-auto d-block h-58px"
                  src={Merchandising}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="w-auto m-auto d-block h-28px"
                  src={NestofixLogo}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img className="w-auto m-auto d-block h-58px" src={RadiantLogo} />
              </SwiperSlide>
              <SwiperSlide>
                <img className="w-auto m-auto d-block h-28px" src={ScaaLogo} />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="w-auto m-auto d-block h-28px"
                  src={TranscellHeader}
                />
              </SwiperSlide>
            </Swiper>
          </Card>
        </section> */}

        {/* <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
          <div className="d-grid-66-33">
            <Card extraClasses="p-50px">
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
              >
                <SwiperSlide>
                  <img
                    src={OrganisationStructure}
                    className="w-100 m-auto d-block mb-5 box-shadow-img"
                  />
                  <h2 className="mb-2 text-primary">{Data["laddingdata"][0]}</h2>
                  <p className="w-90">
                  {Data["laddingdata"][1]}
                  </p>
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={OrganisationStructure}
                    className="w-100 m-auto d-block mb-5 box-shadow-img"
                  />
                  <h2 className="mb-2 text-primary">{Data["laddingdata"][2]}</h2>
                  <p className="w-90">
                  {Data["laddingdata"][3]}
                  </p>
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={OrganisationStructure}
                    className="w-100 m-auto d-block mb-5 box-shadow-img"
                  />
                  <h2 className="mb-2 text-primary">{Data["laddingdata"][4]}</h2>
                  <p className="w-90">
                  {Data["laddingdata"][5]}
                  </p>
                </SwiperSlide>
              </Swiper>
            </Card>

            <Card extraClasses="p-33px">
              <Card extraClasses="bg-grey">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  loop={true}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                >
                  <SwiperSlide>
                    <LandingTestimonial
                      client_name={Data["client"][4]}
                      // designation="Co-Founder"
                      content={Data["client"][0]}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <LandingTestimonial
                      client_name={Data["client"][5]}
                      content={Data["client"][1]}

                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <LandingTestimonial
                      client_name={Data["client"][6]}
                      // designation="COO"
                      content={Data["client"][2]}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <LandingTestimonial
                      client_name={Data["client"][7]}
                      // designation="COO"
                      content={Data["client"][3]}
                    />
                  </SwiperSlide>
                </Swiper>
              </Card>
            </Card>
          </div>
        </section> */}

        <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
          <h2 className="text-center mb-2">{Data["homebanner"][8]}
          </h2>
          <p className="text-center mb-5">
          {Data["homebanner"][7]}
        </p>
          <div className="d-grid-33-33-33">
            <Card extraClasses="p-50px">
              <h3 className="mb-3 color-brown">{Data["homebanner"][1]["Time"]}</h3>
              <p className="mb-3">
                {Data["homebanner"][1]["webParagraph"]}
              </p>
            </Card>
            <Card extraClasses="p-50px">
              <h3 className="mb-3 color-yellow">{Data["homebanner"][2]["Core"]}</h3>
              <p className="mb-3">
                {Data["homebanner"][2]["CoreParagraph"]}
              </p>
            </Card>
            <Card extraClasses="p-50px">
              <h3 className="mb-3 color-light-blue">{Data["homebanner"][3]["Productivity"]}</h3>
              <p className="mb-3">
                {Data["homebanner"][3]["ProductivityParagraph"]}
              </p>
            </Card>
            <Card extraClasses="p-50px">
              <h3 className="mb-3 color-pink">{Data["homebanner"][4]["Employee"]}</h3>
              <p className="mb-3">
                {Data["homebanner"][4]["EmployeeParagraph"]}
              </p>
            </Card>
            <Card extraClasses="p-50px">
              <h3 className="mb-3 color-green">{Data["homebanner"][5]["People"]}</h3>
              <p className="mb-3">
                {Data["homebanner"][5]["PeopleParagraph"]}
              </p>
            </Card>
            <Card extraClasses="p-50px">
              <h3 className="mb-3 color-light-blue">{Data["homebanner"][6]["Payroll"]}</h3>
              <p className="mb-3">
                {Data["homebanner"][6]["PayrollParagraph"]}
              </p>
            </Card>
          </div>
        </section>
        {/* <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
          <Card extraClasses="bg-primary p-50px text-center">
            <h2 className="text-center text-white mb-3">
            Get ready to transform your HR, today!
            </h2>
            <Button title="Request a Demo" onClick={scrollToTop} />
          </Card>
        </section> */}

        {/* <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
          <h2 className="text-center mb-2">Simple & Transparent Pricing</h2>
          <p className="text-center mb-5">
            Pay for what you need (when you need it)
          </p>
          <div className="d-grid-33-33-33">
            <PricingCard
              extraClasses="mr-30"
              title="Free"
              description="Upto 10 employees"
              pricing=" ₹ 0"
              buttonTitle="Get Started"
              onClick={scrollToTop}
              features={[
                "Gps attendance",
                "Basic payroll",
                "Loans and salary advances",
                "Basic task management",
                "Employee self-service",
                "Employee engagement",
                "Smart alert and reports",
                "Real-time analytics",
              ]}
            />
            <PricingCard
              extraClasses="mr-30"
              title="Basic"
              description="Upto 100 employees"
              pricing=" ₹ 80"
              buttonTitle="Get Started"
              onClick={scrollToTop}
              features={[
                "Gps attendance",
                "Basic payroll",
                "Loans and salary advances",
                "Basic task management",
                "Employee self-service",
                "Employee engagement",
                "Smart alert and reports",
                "Real-time analytics",
              ]}
            />

            <PricingCard
              badge="Most Popular"
              extraClasses="mr-30"
              title="Pro"
              description="Upto 100 employees"
              pricing="₹120"
              buttonTitle="Get Started"
              onClick={scrollToTop}
              feature_heading="All Basic plan features plus:"
              features={[
                "Advanced payroll management",
                "Employee onboarding",
                "Attendance monitoring",
                "Gps attendance",
                "Beacon attendance",
                "Remote/hybrid working",
                "Dynamic work shift",
                "Bulk upload options",
                "Smart alert and reports",
                "Custom reports",
                "Advanced task management",
                "Surveys & polls",
                "Custom roles and permissions",
                "Multiple legal entities",
                "Unlimited access to data",
              ]}
            />
          </div>
        </section> */}
        <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
          <Card extraClasses=" p-50px text-center">
            <h2 className="text-center mb-2 text-primary">
            Let's Make IT Happen!
            </h2>
            <Button title={Data["footerParagraph"][1]} type="primary" onClick={scrollToTop} />
          </Card>
        </section>
        {/* <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
          <h2 className="text-center mb-2"> Customer Stories</h2>
          <p className="w-50 text-center m-auto mb-5">
            Read our customer stories, across various industries and team sizes.
          </p>
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
          >
            <SwiperSlide>
              <Landing_test
                client_img={<Ankur />}
                client_name={Data["client"][4]}
                designation=""
                content={Data["client"][0]}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Landing_test
                client_img={<Anubhav />}
                client_name={Data["client"][5]}
                designation=""
                content={Data["client"][1]}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Landing_test
                client_img={<Somya />}
                client_name={Data["client"][6]}
                designation=""
                content={Data["client"][2]}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Landing_test
                client_img={<Adarsh />}
                client_name={Data["client"][7]}
                designation=""
                content={Data["client"][3]}
              />
            </SwiperSlide>
          </Swiper>
        </section> */}
        {/* <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
          <Card extraClasses="p-120px">
            <h2 className="text-center mb-2">{Data["FAQs"][22]}</h2>
            <p className="text-center w-50 mb-5 m-auto">
           {Data["FAQs"][21]}
          </p>
            <FaqLanding
              extraClasses="border-bottom-1 mb-5"
              title={Data["FAQs"][1]}
              detials={Data["FAQs"][2]}
            />
            <FaqLanding
              extraClasses="border-bottom-1 mb-5"
              title={Data["FAQs"][3]}
              detials={Data["FAQs"][4]}
            /> <FaqLanding
              extraClasses="border-bottom-1 mb-5"
              title={Data["FAQs"][5]}
              detials={Data["FAQs"][6]}
            /> <FaqLanding
              extraClasses="border-bottom-1 mb-5"
              title={Data["FAQs"][7]}
              detials={Data["FAQs"][8]}
            /> <FaqLanding
              extraClasses="border-bottom-1 mb-5"
              title={Data["FAQs"][9]}
              detials={Data["FAQs"][10]}
            /> <FaqLanding
              extraClasses="border-bottom-1 mb-5"
              title={Data["FAQs"][11]}
              detials={Data["FAQs"][12]}
            /> <FaqLanding
              extraClasses="border-bottom-1 mb-5"
              title={Data["FAQs"][13]}
              detials={Data["FAQs"][14]}
            /> <FaqLanding
              extraClasses="border-bottom-1 mb-5"
              title={Data["FAQs"][15]}
              detials={Data["FAQs"][16]}
            /> <FaqLanding
              extraClasses="border-bottom-1 mb-5"
              title={Data["FAQs"][17]}
              detials={Data["FAQs"][18]}
            /> <FaqLanding
              extraClasses="border-bottom-1 mb-5"
              title={Data["FAQs"][19]}
              detials={Data["FAQs"][20]}
            /> 
          </Card>
        </section> */}
        <section className="bg-white pricing_banner_section policy">
        <div className="princing_banner_text text-center">
            <h1>
            FAQs
            </h1>
            {/* <p>{Data["TermsofService"][1]}</p> */}
        </div>  
    </section>
    <section className="section_bottom_margin justify-content-center align-items-stretch p-0px" style={{padding:"100px"}}>
    <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
        {/* <Card > */}
        {/* <h2 className="text-center mb-2"> FAQ’S</h2>
        <p className="text-center w-50 mb-5 m-auto">
        If we still haven't answered your queries, <br></br> get in touch at <a className="text-primary" href="tel:+918700983019">+91 8700983019</a>
        </p> */}
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][1]}
            detials={Data["FAQs"][2]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][3]}
            detials={Data["FAQs"][4]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][5]}
            detials={Data["FAQs"][6]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][7]}
            detials={Data["FAQs"][8]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][9]}
            detials={Data["FAQs"][10]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][11]}
            detials={Data["FAQs"][12]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][13]}
            detials={Data["FAQs"][14]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][15]}
            detials={Data["FAQs"][16]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][17]}
            detials={Data["FAQs"][18]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][19]}
            detials={Data["FAQs"][20]}
        />
        {/* </Card> */}
    </section>
    </section>
        {/* <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
          <Card extraClasses=" p-50px text-center">
            <h2 className="text-center mb-2 text-primary">
              Get ready to transform your HR, today!
            </h2>
            <Button title="Request a Demo" type="primary" onClick={scrollToTop} />
          </Card>
        </section> */}
      </Fragment>
    );
  };

  export default Landing;
