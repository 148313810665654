import React, { Fragment, useEffect, useState } from "react";
import classes from "./Navbar.module.css";
import { BsFillGridFill } from "react-icons/bs";
import { BiDollarCircle } from "react-icons/bi";
import { AiOutlineCaretDown } from "react-icons/ai";
import { AiOutlineClose, AiOutlineFieldTime } from "react-icons/ai";
import { Link } from "react-router-dom";
import Button from "../UI/Button";
import Card from "../UI/Card";
import { logo } from "../../assets/images";
import DATA from "../../assets/data.json";

const Navbar = () => {
  const [HamburgerIsOpen, SetHamburgerIsOpen] = useState(false);
  const [showProductDropDown, setShowProductDropDown] = useState(false);
  const [showResourcesDropDown, setShowResourcesDropDown] = useState(false);
  const [showComDropDown, setShowComDropDown] = useState(false);
  const hamburgerClickHandler = () => {
    if (!HamburgerIsOpen) {
      document
        .getElementById("navbar__mobile__div")
        .classList.add("slide_from_top");
      document
        .getElementById("navbar__mobile__div")
        .classList.remove("slide-from-bottom");
    } else {
      document
        .getElementById("navbar__mobile__div")
        .classList.add("slide-from-bottom");
      document
        .getElementById("navbar__mobile__div")
        .classList.remove("slide_from_top");
    }
    SetHamburgerIsOpen(!HamburgerIsOpen);
  };

  const toggleProductDropdown = (e) => {
    e.preventDefault();
    setShowProductDropDown(!showProductDropDown);
    setShowResourcesDropDown(false);
    setShowComDropDown(false);
  };

  const togglenav = (e) => {
    e.preventDefault();


  };

  const toggleResourceDropdown = (e) => {
    e.preventDefault();
    setShowResourcesDropDown(!showResourcesDropDown);
    setShowProductDropDown(false);
    setShowComDropDown(false);
  };
  const toggleComDropdown = (e) => {
    e.preventDefault();
    setShowComDropDown(!showComDropDown);
    setShowResourcesDropDown(false);
    setShowProductDropDown(false);
  };

  return (
    <div className={classes.navbar__div}>
      <div className={classes.navbar__div__logo}>
        <a href="/">
          <img src={logo}
            alt="website_logo"
          // onClick={() => {
          //   setShowProductDropDown(false);
          //   setShowResourcesDropDown(false);
          //   setShowComDropDown(false);
          //   // SetHamburgerIsOpen(false);
          //   // SetHamburgerIsOpen(!HamburgerIsOpen);

          // }}
          />
        </a>
      </div>
      <div className={classes.navbar__div__links}>
        <ul className={classes.navbar__div__links_ul}>
          <li
            className={
              classes.navbar__div__link +
              " " +
              classes.navbar__div__link__has_dropdown
            }
          >
            <Link
              to="#"
              className={classes.nav_barlink_dropdown}
              onClick={toggleProductDropdown}
            >
              {DATA["navli"][0]}
              <AiOutlineCaretDown
                className={classes.navbar__div__link_dropdown_icon}
              />
            </Link>
            {showProductDropDown ? (
              <Fragment>
                <div
                  className={classes.navbar_backdrop}
                  onClick={() => {
                    setShowProductDropDown(!showProductDropDown);
                  }}
                ></div>
                <div className={(classes.productDropdown) + '  ' + (classes.borderRadiusNavbarDropdown)}>
                  <div className="d-grid-66-33">
                    <div className="p-50px py-100px bg-white">
                      <h2 className="text-primary mb-50px">{DATA["navli"][0]}</h2>

                      <div className="d-grid-50-50">
                        <div className={classes.product_text}>
                          <div className="mb-50px">
                            <Link to="/webdevelopment"

                              onClick={() => {
                                setShowProductDropDown(!showProductDropDown);
                              }}

                            >
                              <h4 className="mb-2 hover-brown-color">
                                {DATA["homebanner"][1]["Time"]}
                              </h4>
                              <p>{DATA["homebanner"][1]["webParagraph"]}
                              </p>
                            </Link>
                          </div>
                          <div className="mb-50px">
                            {/* <a href={"/appdevelopment"}> */}
                            <Link to="/appdevelopment"

                              onClick={() => {
                                setShowProductDropDown(!showProductDropDown);
                              }}

                            >
                              <h4 className="mb-2 hover-yellow-color">
                                {DATA["homebanner"][2]["Core"]}
                              </h4>
                              <p className="text-black">
                                {DATA["homebanner"][2]["CoreParagraph"]}
                              </p>
                            </Link>
                          </div>
                          <div className="mb-50px">
                            {/* <a href={"/itservices"}> */}
                            <Link to="/itservices"
                              onClick={() => {
                                setShowProductDropDown(!showProductDropDown);
                              }}>
                              <h4 className="mb-2 hover-green-color">
                                {DATA["homebanner"][5]["People"]}
                              </h4>
                              <p>
                                {DATA["homebanner"][5]["PeopleParagraph"]}
                              </p>
                            </Link>
                          </div>
                        </div>
                        <div className={classes.product_text}>
                          <div className="mb-50px">
                            {/* <a href={"/softwaresolutions"}> */}
                            <Link to="/softwaresolutions"
                              onClick={() => {
                                setShowProductDropDown(!showProductDropDown);
                              }}>
                              <h4 className="mb-2 hover-light-blue-color">
                                {DATA["homebanner"][3]["Productivity"]}
                              </h4>
                              <p>
                                {DATA["homebanner"][3]["ProductivityParagraph"]}
                              </p>
                            </Link>
                          </div>
                          <div className="mb-50px">
                            {/* <a href={"/brandingstrategies"}> */}
                            <Link to="/brandingstrategies"
                              onClick={() => {
                                setShowProductDropDown(!showProductDropDown);
                              }}>
                              <h4 className="mb-2 hover-pink-color">
                                {DATA["homebanner"][4]["Employee"]}
                              </h4>
                              <p>
                                {DATA["homebanner"][4]["EmployeeParagraph"]}
                              </p>
                            </Link>
                          </div>
                          <div className="mb-50px">
                            {/* <a href={"/sco"}> */}
                            <Link to="/sco"
                              onClick={() => {
                                setShowProductDropDown(!showProductDropDown);
                              }}>
                              <h4 className="mb-2 hover-blue-color"> {DATA["homebanner"][6]["Payroll"]}</h4>
                              <p>
                                {DATA["homebanner"][6]["PayrollParagraph"]}
                              </p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" py-100px px-50px text-white bg-primary">
                      <h4 className="mb-50px text-white border-bottom-white pb-3 w-50">
                        {DATA["navbardata"][14]}
                      </h4>
                      <p className="mb-3">School Management System
                      </p>
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </li>
          <li className={classes.navbar__div__link}>
            <Link to="#" onClick={toggleComDropdown} className={classes.nav_barlink_dropdown}>
              {/* {DATA[""]} */}{DATA["navli"][1]}
              <AiOutlineCaretDown
                className={classes.navbar__div__link_dropdown_icon}
              />
            </Link>
            {showComDropDown ? (
              <Fragment>
                <div
                  className={classes.navbar_backdrop}
                  onClick={() => {
                    setShowComDropDown(!showComDropDown);
                  }}
                ></div>
                <div className={(classes.productDropdown) + '  ' + (classes.borderRadiusNavbarDropdown)}>
                  <div className="d-grid-66-33">
                    <div className="p-50px py-100px bg-white">
                      <h2 className="text-primary mb-50px">{DATA["navli"][1]}</h2>

                      <div className="d-grid-50-50">
                        <div className={classes.product_text}>
                          <div className="mb-50px">

                            <Link to={"/about-us"}
                              onClick={() => {
                                setShowComDropDown(!showComDropDown);
                              }}
                            >
                              <h4 className="mb-2 hover-blue-color">{DATA["navbardata"][0]}</h4>
                              <p className="text-black">
                                {DATA["navbardata"][1]}
                              </p>
                            </Link>
                          </div>
                          <div className="mb-50px">
                            {/* <a href={"/customers"}> */}
                            <Link to={"/customers"}
                              onClick={() => {
                                setShowComDropDown(!showComDropDown);
                              }}
                            >
                              <h4 className="mb-2 hover-blue-color">{DATA["navbardata"][2]}</h4>
                              <p>
                                {DATA["navbardata"][3]}
                              </p>
                            </Link>
                          </div>
                          <div className="mb-50px">

                            <Link to={"/careers"}
                              onClick={() => {
                                setShowComDropDown(!showComDropDown);
                              }}
                            >
                              <h4 className="mb-2 hover-blue-color">
                                {DATA["navbardata"][4]}
                              </h4>
                              <p>
                                {DATA["navbardata"][5]}
                              </p>
                            </Link>
                          </div>
                        </div>
                        <div className={classes.product_text}>
                          <div className="mb-50px">


                            <Link to={"/about-us"}
                              onClick={() => {
                                setShowComDropDown(!showComDropDown);
                              }}
                            >
                              <h4 className="mb-2 hover-blue-color">
                                {DATA["navbardata"][6]}
                              </h4>
                              <p>
                                {DATA["navbardata"][7]}
                              </p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" py-100px px-50px text-white bg-primary">
                      <h4 className="mb-3 text-white w-50">
                        {DATA["navbardata"][14]}
                      </h4>
                      <p className="mb-3">
                        {/* Employee Engagement in a mordern dynamic ever */}
                      </p>
                      {/* <p className="mb-3">
                      <img src="https://cdn.pixabay.com/photo/2017/08/30/01/05/milky-way-2695569_960_720.jpg" className="w-50 d-block mb-2"/>

                        Employee Engagement in a mordern dynamic ever growing
                        workspace dynamic
                      </p> */}
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </li>
          {/* <li className={classes.navbar__div__link}>
            <Link to="#">Resources</Link>
          </li> */}
          <li className={classes.navbar__div__link}>
            <Link to="#" onClick={toggleResourceDropdown} className={classes.nav_barlink_dropdown}>
            {DATA["navli"][2]}
              <AiOutlineCaretDown
                className={classes.navbar__div__link_dropdown_icon}
              />
            </Link>
            {showResourcesDropDown ? (
              <Fragment>
                <div
                  className={classes.navbar_backdrop}
                  onClick={() => {
                    setShowResourcesDropDown(!showResourcesDropDown);
                  }}
                ></div>
                <div className={(classes.productDropdown) + '  ' + (classes.borderRadiusNavbarDropdown)}>
                  <div className="d-grid-66-33">
                    <div className="p-50px py-100px bg-white">
                      <h2 className="text-primary mb-50px">{DATA["navli"][2]}</h2>
                      <div className="d-grid-50-50">
                        <div className={classes.product_text}>
                          <div className="mb-50px">

                            <Link to={"/blogs"}
                              onClick={() => {
                                setShowResourcesDropDown(!showResourcesDropDown);
                              }}
                            >
                              <h4 className="mb-2 hover-blue-color">{DATA["navbardata"][8]}</h4>
                              <p className="text-black">
                                {DATA["navbardata"][9]}
                              </p>
                            </Link>
                          </div>
                          <div className="mb-50px">
                            {/* <a href={"/customers"}> */}
                            <Link to={"/faqs"}
                              onClick={() => {
                                setShowResourcesDropDown(!showResourcesDropDown);
                              }}
                            >
                              <h4 className="mb-2 hover-blue-color">{DATA["navbardata"][10]}</h4>
                              <p>
                                {DATA["navbardata"][11]}
                              </p>
                            </Link>
                          </div>
                          <div className="mb-50px">

                            <Link to={"/gallary"}
                              onClick={() => {
                                setShowResourcesDropDown(!showResourcesDropDown);
                              }}
                            >
                              <h4 className="mb-2 hover-blue-color">
                                {DATA["navbardata"][12]}
                              </h4>
                              <p>
                                {DATA["navbardata"][13]}
                              </p>
                            </Link>
                          </div>
                        </div>
                      
                      </div>
                    </div>
                    <div className=" py-100px px-50px text-white bg-primary">
                      <h4 className="mb-3 text-white w-50">
                        {DATA["navbardata"][14]}
                      </h4>
                      <p className="mb-3">
                        {/* Employee Engagement in a mordern dynamic ever */}
                      </p>
                      {/* <p className="mb-3">
                      <img src="https://cdn.pixabay.com/photo/2017/08/30/01/05/milky-way-2695569_960_720.jpg" className="w-50 d-block mb-2"/>

                        Employee Engagement in a mordern dynamic ever growing
                        workspace dynamic
                      </p> */}
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </li>
          <li className={classes.navbar__div__link}>
            <Link to="/about-us"
            
            >{DATA["navbardata"][0]}</Link>
          </li>
        </ul>
        {/* <div className={classes.navbar__div__links_buttons}>
          
          <Button title="login" type="white" />
        </div> */}
      </div>

      {!HamburgerIsOpen ? (
        <BsFillGridFill
          onClick={hamburgerClickHandler}
          className={classes.navbar__hamburger}
        />
      ) : (
        <AiOutlineClose
          onClick={hamburgerClickHandler}
          className={classes.navbar__hamburger}
        />
      )}

      <div id="navbar__mobile__div" className={classes.navbar__mobile}>
        <ul>
          <li
            className={
              classes.navbar__div__link +
              " " +
              classes.navbar__div__link__has_dropdown
            }
          >
            <Link
              to="#"
              className={classes.nav_barlink_dropdown}
              onClick={toggleProductDropdown}
            >
              {DATA["navli"][0]}
              <AiOutlineCaretDown
                className={classes.navbar__div__link_dropdown_icon}
              />
            </Link>
            {showProductDropDown ? (
              <Fragment>
                <div
                  onClick={() => {
                    setShowProductDropDown(!showProductDropDown);
                  }}
                ></div>
                <div>
                  <ul className={classes.footer_ul} style={{ marginLeft: "20px" }}>
                    <li >
                      <a href="/webdevelopment">{DATA["footerli"][0][1]}</a>
                    </li>
                    <li>
                      <a href="/appdevelopment">{DATA["footerli"][0][2]}</a>
                    </li>
                    <li>
                      <a href="/softwaresolutions">{DATA["footerli"][0][3]}</a>
                    </li>
                    <li>
                      <a href="/brandingstrategies">{DATA["footerli"][0][4]}</a>
                    </li>
                    <li>
                      <a href="/itservices">{DATA["footerli"][0][5]}</a>
                    </li>
                    <li>
                      <a href="/sco">{DATA["footerli"][0][6]}</a>
                    </li>
                  </ul>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </li>
          <li className={classes.navbar__div__link}>
            <Link to="#" onClick={toggleComDropdown} className={classes.nav_barlink_dropdown}>
              {/* {DATA[""]} */}{DATA["navli"][1]}
              <AiOutlineCaretDown
                className={classes.navbar__div__link_dropdown_icon}
              />
            </Link>
            {showComDropDown ? (
              <Fragment>
                <div

                  onClick={() => {
                    setShowComDropDown(!showComDropDown);
                  }}
                ></div>
                <ul className={classes.footer_ul} style={{ marginLeft: "20px" }}>
                  <li>
                    <a href="/about-us">{DATA["footerli"][4][1]}</a>
                  </li>
                  <li>
                    <a href="/customers">{DATA["footerli"][4][2]}</a>
                  </li>
                  <li>
                    <a href="/careers">{DATA["footerli"][4][3]}</a>
                  </li>
                  <li>
                    <a href="/about-us">{DATA["footerli"][4][4]}</a>
                  </li>
                </ul>

              </Fragment>
            ) : (
              ""
            )}
          </li>
          <li className={classes.navbar__div__link}>
            <Link to="#" onClick={toggleResourceDropdown} className={classes.nav_barlink_dropdown}>
              {DATA["navli"][2]} <AiOutlineCaretDown
                className={classes.navbar__div__link_dropdown_icon}
              />
            </Link>
            {showResourcesDropDown ? (
              <Fragment>
                <div

                  onClick={() => {
                    setShowResourcesDropDown(!showResourcesDropDown);
                  }}
                ></div>
                <ul className={classes.footer_ul} style={{ marginLeft: "20px" }}>
                  <li><a href="/blogs">{DATA["footerli"][1][1]}</a></li>
                  <li><a href="/faqs">{DATA["footerli"][1][2]}</a></li>
                  <li><a href="/gallary">{DATA["footerli"][1][3]}</a></li>
                </ul>
              </Fragment>
            ) : (
              ""
            )}
          </li>
          <li className={classes.navbar__div__link}>
            <a href="/about-us">{DATA["navbardata"][0]}</a>
          </li>
        </ul>
        <div className={classes.navbar__div__links_buttons}>
          {/* <button>Login</button>
          <button>Free Trail</button> */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
