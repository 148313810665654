import React, { Fragment } from "react";
import PricingCard from "../components/pricing_card/PricingCard";
import Button from "../components/UI/Button";
import Helmet from 'react-helmet';
import Data from "../assets/data.json";
import FaqLanding from "../components/faq_landing/FaqLanding";
import Card from "../components/UI/Card";

const RefundPolicy = () => {
return (
    <Fragment>
    <Helmet>
        <title>
        {Data["RefundPolicy"][0]}
        </title>
        <meta
        name="description"
        content={Data["metadata"][27]}
        />
    </Helmet>
    <section className="bg-white pricing_banner_section policy">
        <div className="princing_banner_text text-center">
            <h1 className="fontsizeh1">
                
                {Data["RefundPolicy"][0]}
            </h1>
            {/* <p>{Data["TermsofService"][1]}</p> */}
        </div>  
    </section>
    <section className="section_bottom_margin justify-content-center align-items-stretch p-0px" style={{padding:"100px"}}>
    <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
        {/* <Card > */}
        {/* <h2 className="text-center mb-2"> FAQ’S</h2>
        <p className="text-center w-50 mb-5 m-auto">
        If we still haven't answered your queries, <br></br> get in touch at <a className="text-primary" href="tel:+918700983019">+91 8700983019</a>
        </p> */}

<h4 className="text-primary mb-3">{Data["RefundPolicy"][2]}</h4>
                    <p className="mb-3">{Data["RefundPolicy"][3]}</p>
                    <h4 className="text-primary mb-3">{Data["RefundPolicy"][4]}</h4>
                    <p className="mb-3">{Data["RefundPolicy"][5]}</p>
                    <h4 className="text-primary mb-3">{Data["RefundPolicy"][6]}</h4>
                    <p className="mb-3">{Data["RefundPolicy"][7]}</p>
                    <h4 className="text-primary mb-3">{Data["RefundPolicy"][8]}</h4>
                    <p className="mb-2">{Data["RefundPolicy"][9]}</p>
                    <p className="mb-3">{Data["RefundPolicy"][10]}</p>
        {/* <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["RefundPolicy"][2]}
            detials={Data["RefundPolicy"][3]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["RefundPolicy"][4]}
            detials={Data["RefundPolicy"][5]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["RefundPolicy"][6]}
            detials={Data["RefundPolicy"][7]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["RefundPolicy"][8]}
            detials={Data["RefundPolicy"][9]}
        /> */}
        {/* </Card> */}
    </section>
    </section>
    </Fragment>
);
};

export default RefundPolicy;
