import React, { Fragment } from "react";
import LandingBanner from "../components/landing_banner/LandingBanner";
import Card from "../components/UI/Card";
import WorkCompanion from "../components/work_companion/WorkCompanion";
import Helmet from 'react-helmet';
import Data from "../assets/data.json";
import {Software1, Software2, Software3} from "../assets/images"

const Softwaresolutions = () => {
  return (
    <Fragment>
      <Helmet>
        <title>
        {Data["SoftwareSolutionsData"][0]}
        </title>
        <meta
          name="description"
          content={Data["metadata"][31]}
        />
      </Helmet>
      <LandingBanner
        title={Data["SoftwareSolutionsData"][0]}
        textBold=""
        text={Data["SoftwareSolutionsData"][2]}
        class="color-light-blue section_bottom_margin"
        cta_title={Data["footerParagraph"][1]}
        cta_link="www.google.com"
        type="primary"
        headingClass="color-light-blue"
        extraClasses="softPadding"
      />

      <section className="section_bottom_margin first_section ml-30 mr-30 max-w-1600px d-sm-block">
        <div className="section_2-3 mr-30 mr-sm-0">
          <Card>
            <div className="first_section_text_div ">
              <h2 className="color-light-blue mb-5">{Data["SoftwareSolutionsData"][3]}</h2>
              <p className="mb-5">{Data["SoftwareSolutionsData"][4]}</p>
              <h2 className="color-light-blue mb-5">{Data["SoftwareSolutionsData"][5]}</h2>
              <ul className="task_management_ul">
                <li><p className="color-light-blue mb-2">{Data["SoftwareSolutionsData"][7]}</p><p>{Data["SoftwareSolutionsData"][8]}</p></li>
                <li><p className="color-light-blue mb-2">{Data["SoftwareSolutionsData"][9]}</p><p>{Data["SoftwareSolutionsData"][10]}</p></li>
                <li><p className="color-light-blue mb-2">{Data["SoftwareSolutionsData"][11]}</p><p>{Data["SoftwareSolutionsData"][12]}</p></li>
                <li><p className="color-light-blue mb-2">{Data["SoftwareSolutionsData"][13]}</p><p>{Data["SoftwareSolutionsData"][14]}</p></li>
                {/* <li><p>{Data["SoftwareSolutionsData"][15]}</p><br></br>{Data["SoftwareSolutionsData"][16]}</li>
                <li><p>{Data["SoftwareSolutionsData"][17]}</p><br></br>{Data["SoftwareSolutionsData"][18]}</li> */}
              </ul>
            </div>
          </Card>
        </div>
        <div className="section_1-3 ">
          <Card>
            <div className="first_section_illustration_div">
              <img className="img-center w-80" src={Software1} alt="img_logo" />
            </div>
          </Card>
        </div>
      </section>

      <section className="section_bottom_margin d-flex ml-30 mr-30 max-w-1600px d-sm-block">
        <div className="section_1-3 mr-30 mr-sm-0">
          <Card extraClasses="px-5">
            <div className="first_section_illustration_div">
              <img className="img-center w-60" src={Software2} alt="img_logo" />
            </div>
          </Card>
        </div>
        <div className="section_2-3 ">
          <Card>
            <div className="first_section_text_div">
              <h2 className="color-light-blue mb-5">{Data["SoftwareSolutionsData"][19]}</h2>
              <ul className="task_management_ul">
              <li><p className="color-light-blue">{Data["SoftwareSolutionsData"][20]}</p><br></br>{Data["SoftwareSolutionsData"][21]}</li>
                <li><p className="color-light-blue">{Data["SoftwareSolutionsData"][22]}</p><br></br>{Data["SoftwareSolutionsData"][23]}</li>
                <li><p className="color-light-blue">{Data["SoftwareSolutionsData"][24]}</p><br></br>{Data["SoftwareSolutionsData"][25]}</li>
                <li><p className="color-light-blue">{Data["SoftwareSolutionsData"][26]}</p><br></br>{Data["SoftwareSolutionsData"][27]}</li>
                <li><p className="color-light-blue">{Data["SoftwareSolutionsData"][28]}</p><br></br>{Data["SoftwareSolutionsData"][29]}</li>
                {/* <li><p className="color-light-blue">{Data["SoftwareSolutionsData"][30]}</p><br></br>{Data["SoftwareSolutionsData"][31]}</li> */}
              </ul>
            </div>
          </Card>
        </div>
      </section>
      <WorkCompanion />
      <section className="section_bottom_margin d-flex ml-30 mr-30 max-w-1600px d-sm-block">
        <div className="section_2-3 mr-30  mr-sm-0">
          <Card>
            <div className="first_section_text_div">
              <h2 className="color-light-blue mb-3">{Data["SoftwareSolutionsData"][32]}</h2>
              
              <ul className="task_management_ul">
              <li><p className="color-light-blue">{Data["SoftwareSolutionsData"][33]}</p><br></br>{Data["SoftwareSolutionsData"][34]}</li>
                <li><p className="color-light-blue">{Data["SoftwareSolutionsData"][35]}</p><br></br>{Data["SoftwareSolutionsData"][36]}</li>
                <li><p className="color-light-blue">{Data["SoftwareSolutionsData"][37]}</p><br></br>{Data["SoftwareSolutionsData"][38]}</li>
                <li><p className="color-light-blue">{Data["SoftwareSolutionsData"][39]}</p><br></br>{Data["SoftwareSolutionsData"][40]}</li>
                {/* <li><p className="color-light-blue">{Data["SoftwareSolutionsData"][41]}</p><br></br>{Data["SoftwareSolutionsData"][42]}</li>
                <li><p className="color-light-blue">{Data["SoftwareSolutionsData"][43]}</p><br></br>{Data["SoftwareSolutionsData"][44]}</li> */}
              </ul>
            </div>
          </Card>
        </div>
        <div className="section_1-3 ">
          <Card extraClasses="px-5">
            <div className="first_section_illustration_div">
              <img className="img-center w-60" src={Software3} alt="img_logo" />
            </div>
          </Card>
        </div>
      </section>
      <section className="section_bottom_margin ml-30 mr-30">
          <div className="text-center ">
            <div className="section1-2 mr-30 ">
               <p><i className="texti" style={{marginLeft:"100px",marginRight:"100px"}}>{Data["SoftwareSolutionsData"][53]}</i></p>
            </div>
            
          </div>
        </section>
    </Fragment>
  );
};

export default Softwaresolutions;
