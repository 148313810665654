import React, { Fragment} from "react";
import Card from "../components/UI/Card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import Button from "../components/UI/Button";
import WorkCompanion from "../components/work_companion/WorkCompanion";
import Helmet from "react-helmet";
import {
  RiSmartphoneFill,
  RiHandCoinFill,
  RiUser3Fill,
  RiHammerFill,
  RiErrorWarningFill,
  RiBriefcase4Fill,
  RiScissorsFill,
  RiHealthBookFill,
} from "react-icons/ri";
import Testimonial from "../components/testimonial/Testimonial";
import Data from "../assets/data.json";
import {Somya , Anubhav, Adarsh, Ankur,HomepageVideo} from "../assets/images";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>
          Home Page
        </title>
        <meta
          name="description"
          content={Data["metadata"][19]}
        />
      </Helmet>
      <section className="section_bottom_margin">
        <div className="home_page_banner_text text-center">
          <div className="home_page_banner_text_div mt-sm-3">
            <h1 className="d-flex align-items-center justify-content-flex-end d-sm-flex-direction-column">
              Unlock
              <div className="mt-sm-3 div_top_text_animation">
                <span className="scroller">
                  <span>
                    <div className="text-primary d-inline-block">{Data["Unlock"][0]}</div>
                    <br></br>
                    <div className="color-brown d-inline-block">{Data["Unlock"][1]} </div>
                    <br></br>
                    <div className="color-pink d-inline-block">{Data["Unlock"][2]}</div>
                    <br></br>
                    <div className="color-yellow d-inline-block">
                    {Data["Unlock"][3]}
                    </div>
                  </span>
                </span>
              </div>
            </h1>
            <h2 className="text-primary mb-5">
              {Data["Unlock_p"]}
            </h2>
           
          </div>
        </div>
        <div className="home_page_banner_image m-mt190">
          <div className="text-center" style={{marginBottom:"20px"}}>
<Link to="/about-us">
            <button className="button_home_primary d-inline-block">
              {" "}
              {Data["LearnMore"]}
            </button>
            </Link>
            <Link to="/contact-us">
            <button className="button_home_alternative ml-5 d-inline-block">
              {" "}
              {Data["RequestDemo"]}
            </button>
            </Link>
          </div>
        
           <video loop autoPlay width="1000px" style={{ marginLeft: "200px" }}>
            <source src={HomepageVideo} type="video/mp4" />
          </video>
        </div>
      </section>
      {/* <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
        <Card extraClasses="text-center py-40px px-120px">
          <h2 className="mb-2">Meet Our Work Companions</h2>
          <p className="mb-5">
            Trusted by the top leaders from various industries
          </p>
          <Swiper
            slidesPerView={7}
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className={"client_logo_slider"}
          >
            <SwiperSlide>
              <img className="w-auto m-auto d-block h-58px" src={ActoFitLogo} />
            </SwiperSlide>
            <SwiperSlide>
              <img className="w-50 m-auto d-block" src={CofeeLogo} />
            </SwiperSlide>
            <SwiperSlide>
              <img className="w-auto m-auto d-block h-58px" src={EdgeWell} />
            </SwiperSlide>
            <SwiperSlide>
              <img className="w-auto m-auto d-block h-28px" src={Femella} />
            </SwiperSlide>
            <SwiperSlide>
              <img className="w-auto m-auto d-block h-28px" src={logo_new} />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="w-auto m-auto d-block h-58px"
                src={Merchandising}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="w-auto m-auto d-block h-28px"
                src={NestofixLogo}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img className="w-auto m-auto d-block h-58px" src={RadiantLogo} />
            </SwiperSlide>
            <SwiperSlide>
              <img className="w-auto m-auto d-block h-28px" src={ScaaLogo} />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="w-auto m-auto d-block h-28px"
                src={TranscellHeader}
              />
            </SwiperSlide>
          </Swiper>
        </Card>
      </section> */}

      <section className="section_bottom_margin mb-sm-0">
        <Card extraClasses="bg-primary text-white super_charge_payroll_home_card">
          <h2 className="text-center text-white mb-3">
            {Data["homebanner"][0]}
          </h2>
          <p className="w-50 text-center mx-auto mb-5 w-sm-100">
          {Data["homebanner"][7]}
          </p>
          <div className="super_charge_payroll_home_card_div">
          <Link to="/webdevelopment">
            <Card extraClasses="px-50px py-100px text-white hover-brown">
              <h2 className="mb-3 text-white text-sm-center">{Data["homebanner"][1]["Time"]}</h2>
              <p className="para_font_600_20px text-sm-center">
                {Data["homebanner"][1]["webParagraph"]}
              </p>
            </Card>
            </Link>
            <Link to="/appdevelopment">
            <Card extraClasses="px-50px py-100px text-white hover-yellow">
              <h2 className="mb-3 text-white text-sm-center">{Data["homebanner"][2]["Core"]}</h2>
              <p className="para_font_600_20px text-sm-center">{Data["homebanner"][2]["CoreParagraph"]}</p>
            </Card>
            </Link>
            <Link to="/softwaresolutions">
            <Card extraClasses="px-50px py-100px text-white hover-light-blue">
              <h2 className="mb-3 text-white text-sm-center">{Data["homebanner"][3]["Productivity"]}</h2>
              <p className="para_font_600_20px text-sm-center">{Data["homebanner"][3]["ProductivityParagraph"]}</p>
            </Card>
            </Link>
            <Link to="/brandingstrategies">
            <Card extraClasses="px-50px py-100px text-white hover-pink ">
              <h2 className="mb-3 text-white text-sm-center">{Data["homebanner"][4]["Employee"]}</h2>
              <p className="para_font_600_20px text-sm-center">{Data["homebanner"][4]["EmployeeParagraph"]}</p>
            </Card>
            </Link>
            <Link to="/itservices">
            <Card extraClasses="px-50px py-100px text-white hover-green ">
              <h2 className="mb-3 text-white text-sm-center">{Data["homebanner"][5]["People"]}</h2>
              <p className="para_font_600_20px text-sm-center">
              {Data["homebanner"][5]["PeopleParagraph"]}
              </p>
            </Card>
            </Link>
            <Link to="/sco">
            <Card extraClasses="px-50px py-100px text-white hover-grey ">
              <h2 className="mb-3 text-white text-sm-center">{Data["homebanner"][6]["Payroll"]}</h2>
              <p className="para_font_600_20px text-sm-center">{Data["homebanner"][6]["PayrollParagraph"]}</p>
            </Card>
            </Link>
          </div>
        </Card>
      </section>

      {/* <section className="section_bottom_margin ml-30 mr-30">
        <h2 className="text-center mb-3">Awards</h2>
        <p className="text-center mb-5">
          We have been recognised, applauded and awarded by
        </p>
        <div className="d-grid-25-25-25-25">
          <Card extraClasses="text-center py-40px ">
            <img className="h-58px mb-3" src={RadiantLogo} />
            <h3>Employee Engagement</h3>
          </Card>
          <Card extraClasses="text-center py-40px ">
            <img className="h-58px mb-3" src={RadiantLogo} />
            <h3>Employee Engagement</h3>
          </Card>
          <Card extraClasses="text-center py-40px ">
            <img className="h-58px mb-3" src={RadiantLogo} />
            <h3>Employee Engagement</h3>
          </Card>
          <Card extraClasses="text-center py-40px ">
            <img className="h-58px mb-3" src={RadiantLogo} />
            <h3>Employee Engagement</h3>
          </Card>
        </div>
      </section> */}
      <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
        <div className="d-grid-33-66">
          <Card extraClasses="px-50px py-100px">
            <div>
              <h2 className="mb-3">
              Your Trusted IT Partner Elevate Your Business with Venkat Technologies
              </h2>
              <Button title={Data["LearnMore"]} type="primary" link="/about-us" />
            </div>
          </Card>

          <Card>

          {/* <iframe width="100%" height="100%" className="border-radius-14px h-sm-200px" src="https://www.youtube.com/embed/NEW_VIDEO_ID" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" alt="youtube_video"></iframe> */}
          <iframe
  title="YouTube Video"
  width="100%"
  height="100%"
  className="border-radius-14px h-sm-200px"
  src="https://www.youtube.com/embed/NEW_VIDEO_ID"
  frameborder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
></iframe>
          </Card>
        </div>
      </section>
       <WorkCompanion /> 
      <section className="section_bottom_margin max-w-1600px mb-sm-0 ">
        <h2 className="text-center mb-3">{Data["Why"][0]}</h2>
        <p className="w-60 mb-2 text-center mx-auto">
        {Data["Why"][1]}
        </p>
        <p className="w-60 mb-5 text-center mx-auto">
        {Data["Why"][2]}
        </p>
        <div className="d-grid-25-25-25-25 px-200" style={{textAlign:"center"}}>
          <Card extraClasses="px-50px py-50px">
            <div className="text-sm-center">{<RiSmartphoneFill className="why_us_home_icons mb-3" />}</div>
            <h3>{Data["WhyBox"][0]}</h3>
          </Card>
          <Card extraClasses="px-50px py-50px">
            <div className="text-sm-center">{<RiHandCoinFill className="why_us_home_icons mb-3" />}</div>
            <h3>{Data["WhyBox"][1]}</h3>
          </Card>
          <Card extraClasses="px-50px py-50px">
            <div className="text-sm-center">
              {<RiErrorWarningFill className="why_us_home_icons mb-3" />}
            </div>

            <h3>{Data["WhyBox"][2]}</h3>
          </Card>
          <Card extraClasses="px-50px py-50px">
            <div className="text-sm-center">{<RiUser3Fill className="why_us_home_icons mb-3" />}</div>
            <h3>{Data["WhyBox"][3]}</h3>
          </Card>
        </div>
      </section>



      <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
        <Card extraClasses="px-sm-30 py-sm-2rem p-100">
          <div className="d-grid-33-66">
            <div className="w-50 my-auto w-sm-100">
              <h2 className="mb-3 text-sm-center"> What our client says</h2>
              <p className="fw-500 text-sm-center mb-sm-3">Words of love from our clients</p>
            </div>
            <Card>
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
              >
                <SwiperSlide>
                  <Testimonial
                    client_img={<Ankur />}
                    client_name={Data["client"][4]}
                    // designation="ABC at somersault"
                    content={Data["client"][0]}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Testimonial
                    client_img={<Anubhav />}
                    client_name={Data["client"][5]}
                    // designation="ABC at somersault"
                    content={Data["client"][1]}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Testimonial
                    client_img={<Somya />}
                    client_name={Data["client"][6]}
                    // designation="ABC at somersault"
                    content={Data["client"][2]}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Testimonial
                    client_img={<Adarsh />}
                    client_name={Data["client"][7]}
                    // designation="ABC at somersault"
                    content={Data["client"][3]}
                  />
                </SwiperSlide>
              </Swiper>
            </Card>
          </div>
        </Card>
      </section>
      <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
        <Card extraClasses="px-200 py-100px powering_teams">
        <h2 className="text-center mb-3 text-primary">
        {Data["Powerdata"][2]}
          </h2>
          <h2 className="text-center mb-3">
          {Data["Powerdata"][0]}
          </h2>
          <p className="text-center mb-5 w-60 mx-auto">
          {Data["Powerdata"][1]}
          </p>
         
          <div className="d-grid-25-25-25-25">
            <div className="text-center">
              {
                <RiScissorsFill className="bg-yellow border-radius-14px mb-5 text-white p-33px font-size-107px" />
              }

              <h3 className="mb-3">{Data["Poweringboxdata"][0]}</h3>
              <p>
              {Data["Poweringboxdata"][1]}
              </p>
            </div>
            <div className="text-center">
              {
                <RiHealthBookFill className="bg-orange border-radius-14px mb-5 text-white p-33px font-size-107px" />
              }

              <h4 className="mb-3">{Data["Poweringboxdata"][2]}</h4>
              <p>
              {Data["Poweringboxdata"][3]}
              </p>
            </div>
            <div className="text-center">
              {
                <RiBriefcase4Fill className="bg-green border-radius-14px mb-5 text-white p-33px font-size-107px" />
              }

              <h4 className="mb-3">{Data["Poweringboxdata"][4]} </h4>
              <p>
              {Data["Poweringboxdata"][5]}
              </p>
            </div>
            <div className="text-center">
              {
                <RiHammerFill className="bg-primary border-radius-14px mb-5 text-white p-33px font-size-107px" />
              }

              <h4 className="mb-3">{Data["Poweringboxdata"][6]} </h4>
              <p>
              {Data["Poweringboxdata"][7]}
              </p>
            </div>
          </div>
        </Card>
      </section>
    </Fragment>
  );
};

export default HomePage;
