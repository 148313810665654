import React from 'react'
import Button from '../UI/Button';
import classes from './Landing.module.css';
import Logo from '../../assets/logo.png'
const LandingNav = () => {
  return (
    // <nav className={classes.landing_nav}>
    //   <div></div>
    //   <div>
    //     <button className={classes.landing_nav__button}>Free Trail</button>
    //   </div>
    //   </nav>
    <nav className={classes.landing_nav}>
        <div className='text-center'><img src={Logo}/></div>
    </nav>
  )
}

export default LandingNav