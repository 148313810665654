import React, { useEffect } from "react";
import LandingBanner from "../components/landing_banner/LandingBanner";
import { Fragment } from "react";
import Card from "../components/UI/Card";

import WorkCompanion from "../components/work_companion/WorkCompanion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import Helmet from 'react-helmet';
import Data from '../assets/data.json';
import {App1, App2, App3, App4, App5} from "../assets/images"
const AppDevelopment = () => {
  return (
    <Fragment>
      <Helmet>
      <title>
      {Data["AppDevelopmentData"][0]}
        </title>
        <meta
          name="description"
          content={Data["metadata"][3]}
        />
      </Helmet>
      <LandingBanner
        title={Data["AppDevelopmentData"][0]}
        textBold=""
        text={Data["AppDevelopmentData"][2]}
        class="color-brown section_bottom_margin"
        cta_title={Data["footerParagraph"][1]}
        cta_link="www.google.com"
        type="primary"
        headingClass="color-brown"
      />

      <section className="section_bottom_margin d-grid-66-33 ml-30 mr-30 max-w-1600px">
        <div className="mr-30 mr-sm-0">
          <Card>
            <div className="first_section_text_div">
              <div className="heading_left_horizontal bg-orange "></div>
              <h2 className="color-brown  mb-3 mt-sm-60px">{Data["AppDevelopmentData"][3]}</h2>
             <p>{Data["AppDevelopmentData"][4]}</p>
              <div className="mb-3">
              <h2 className="color-brown  mt-5 mb-3">{Data["AppDevelopmentData"][5]}</h2>
              <p className="mb-3">{Data["AppDevelopmentData"][6]}</p>
              <p><b>{Data["AppDevelopmentData"][7]} :</b> {Data["AppDevelopmentData"][8]}</p><br></br>
              <p><b>{Data["AppDevelopmentData"][9]} :</b> {Data["AppDevelopmentData"][10]}</p><br></br>
              <p><b>{Data["AppDevelopmentData"][11]} :</b> {Data["AppDevelopmentData"][12]}</p><br></br>
              <p><b>{Data["AppDevelopmentData"][13]} :</b> {Data["AppDevelopmentData"][14]}</p><br></br>
              <p><b>{Data["AppDevelopmentData"][15]} :</b> {Data["AppDevelopmentData"][16]}</p>
              </div>
            </div>
          </Card>
        </div>

        <div className="">
          <Card>
            <div className="first_section_illustration_div">
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className={" m-auto h-100 py-100px"}
              >
                <SwiperSlide>
                  <img
                    className="w-80 m-auto d-block"
                    src={App1}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="w-80 m-auto d-block"
                    src={App2}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </Card>
        </div>
      </section>

      <section className="section_bottom_margin ml-30 mr-30 ">
        <Card>
          <div className="p-120px bg-light-brown border-radius-20 px-sm-30 py-sm-2rem">
            <div className="max-w-1600px">
              <h2 className="color-brown mb-3">{Data["AppDevelopmentData"][17]}</h2>
              <div className="d-flex mb-5 d-sm-block">
                <div className="section1-2 mr-30">
                  <h4 className="color-brown mb-2">{Data["AppDevelopmentData"][18]}</h4>
                  <p>
                  {Data["AppDevelopmentData"][19]}
                  </p>
                </div>
                <div className="section1-2">
                  <h4 className="color-brown mb-2">{Data["AppDevelopmentData"][20]}</h4>
                  <p>
                  {Data["AppDevelopmentData"][21]}
                  </p>
                </div>
              </div>
              <div className="d-flex d-sm-block">
                <div className="section1-2 mr-30">
                  <h4 className="color-brown mb-2">{Data["AppDevelopmentData"][22]}</h4>
                  <p>
                  {Data["AppDevelopmentData"][23]}
                  </p>
                </div>
                <div className="section1-2">
                  <h4 className="color-brown mb-2">{Data["AppDevelopmentData"][24]}</h4>
                  <p>
                  {Data["AppDevelopmentData"][25]}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </section>
      <WorkCompanion />
      <section className="section_bottom_margin ml-30 mr-30">
        <Card>
          <div className="p-120px max-w-1600px px-sm-30 py-sm-2rem">
            <h2 className="color-brown mb-3">{Data["AppDevelopmentData"][30]}</h2>
            <h4 className="color-brown  mb-3">
            {Data["AppDevelopmentData"][31]}
            </h4>
            <p className="mb-5 timeandattendance_para_font">
            {Data["AppDevelopmentData"][32]}
            </p>
            <div className="d-flex leavemng_tna">
             
              <div className="section1-2 mr-30 mr-sm-0">
                <Card boxshadow="1">
                  <div className="px-5 py-2 padding_mobile_1rem">
                    <h4 className="color-brown  mb-2">{Data["AppDevelopmentData"][33]}</h4>
                    <p className="mb-5 timeandattendance_para_font">
                    {Data["AppDevelopmentData"][34]}
                    </p>
                    <img className="d-block m-auto w-80" src={App3} />
                  </div>
                </Card>
              </div>
              <div className="section1-2 mr-30 mr-sm-0 ">
                <Card boxshadow="1">
                  <div className="px-5 py-2 padding_mobile_1rem">
                    <h4 className="color-brown  mb-2">{Data["AppDevelopmentData"][35]}</h4>
                    <p className="mb-5 timeandattendance_para_font">
                    {Data["AppDevelopmentData"][36]}
                    </p>
                    <img
                      className="d-block m-auto w-80"
                      src={App4}
                    />
                  </div>
                </Card>
              </div>
              <div className="section1-2 mr-30 mr-sm-0">
                <Card boxshadow="1">
                  <div className="px-5 py-2 padding_mobile_1rem">
                    <h4 className="color-brown  mb-2">{Data["AppDevelopmentData"][37]}</h4>
                    <p className="mb-5">
                    {Data["AppDevelopmentData"][38]}
                    </p>
                    <img className="d-block m-auto w-80" src={App5} />
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </Card>
      </section>
      <section className="section_bottom_margin ml-30 mr-30">
          <div className="text-center ">
            <div className="section1-2 mr-30 ">
               <p><i className="texti" style={{marginLeft:"100px",marginRight:"100px"}}>{Data["AppDevelopmentData"][51]}</i></p>
            </div>
            
          </div>
        </section>
    </Fragment>
  );
};

export default AppDevelopment;
