import React, { Fragment } from "react";
import Card from "../components/UI/Card";
import { Link } from "react-router-dom";
import Helmet from 'react-helmet';
import Data from "../assets/data.json";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import Testimonial from "../components/testimonial/Testimonial";
import {Somya , Anubhav, Adarsh, Ankur,HomepageVideo} from "../assets/images";

const AboutUs = () => {
  return (
    <Fragment>
      <Helmet>
        <title>
        {Data["AboutUsdata"][0]}
        </title>
        <meta
          name="description"
          content={Data["metadata"][1]}
        />
      </Helmet>
      <section className="section_bottom_margin about_us_section">
        <div className="about_us_section_text max-w-1600px">
          <h1>{Data["AboutUsdata"][0]}</h1>
          <p>
            {Data["navbardata"][1]}
          </p>
        </div>
      </section>

      <section className="ml-30 mr-30 section_bottom_margin max-w-1600px">
        <Card extraClasses="p-120px p-relative px-sm-30 py-sm-2rem">
          <div className="heading_left_horizontal bg-primary" style={{width: 69+'px'}}></div>
          <h2 className="mb-3 mt-sm-60px">{Data["AboutUsdata"][1]}</h2>
          <p className="mb-2 w-90">
          {Data["AboutUsdata"][2]}
          </p>
          <p className="w-90">
          {Data["AboutUsdata"][3]}
          </p>
        </Card>
      </section>

      <section className="ml-30 mr-30 section_bottom_margin max-w-1600px">
        <h2 className="mb-5 text-center">{Data["homebanner"][9]}</h2>
        <div className="px-200 mt-50px  about_us_product_card_div d-sm-block p-sm-0">
          <Card extraClasses="about_us_product_card d-sm-padding-30px mb-sm-3">
            <h2 className="color-brown mb-3 aboutus_page_heading_3_cards">
           {Data["homebanner"][1]["Time"]}
              </h2>
            <p className="mb-3 para_font_600_20px">
            {Data["homebanner"][1]["webParagraph"]}
            </p>

            <Link to="/webdevelopment">Learn More</Link>
          </Card>
          <Card extraClasses="about_us_product_card d-sm-padding-30px mb-sm-3">
            <h2 className="color-yellow mb-3">{Data["homebanner"][2]["Core"]}</h2>
            <p className="mb-3 fw-500">
            {Data["homebanner"][2]["CoreParagraph"]}
            </p>
            
          </Card>
          <Card extraClasses="about_us_product_card  d-sm-padding-30px mb-sm-3">
            <h2 className="color-light-blue mb-3">{Data["homebanner"][3]["Productivity"]}</h2>
            <p className="mb-3 fw-500">
            {Data["homebanner"][3]["ProductivityParagraph"]}
            </p>

            <Link to="/softwaresolutions">Learn More</Link>
          </Card>
          <Card extraClasses="about_us_product_card  d-sm-padding-30px mb-sm-3">
            <h2 className="color-pink mb-3">{Data["homebanner"][4]["Employee"]}</h2>
            <p className="mb-3 fw-500">
            {Data["homebanner"][4]["EmployeeParagraph"]}
            </p>

            <Link to="/brandingstrategies">Learn More</Link>
          </Card>
          <Card extraClasses="about_us_product_card  d-sm-padding-30px mb-sm-3 ">
            <h2 className="color-green mb-3"> {Data["homebanner"][5]["People"]}</h2>
            <p className="mb-3 fw-500">
            {Data["homebanner"][5]["PeopleParagraph"]}
            </p>

            <Link to="/itservices">Learn More</Link>
          </Card>
          <Card extraClasses="about_us_product_card d-sm-padding-30px mb-sm-3 ">
            <h2 className="color-light-grey mb-3">{Data["homebanner"][6]["Payroll"]}</h2>
            <p className="mb-3 fw-500">
            {Data["homebanner"][6]["PayrollParagraph"]}
            </p>

            <Link to="/sco">Learn More</Link>
          </Card>
        </div>
      </section>
      {/* <section className="section_bottom_margin max-w-1600px">
        <h2 className="text-center mb-3"> Our Team</h2>
        <p className="text-center m-auto  w-25">
          {" "}
          Designed to infuse data-driven decisions, accurate insights, and
          real-time reports
        </p>
        <div className="ml-30 mr-30 mt-5 section_our_team_div">
          <div>
            <img src={Dhruv} className="w-100 team_img" />
          </div>
          <div>
            <img src={Aashu} className="w-100 team_img" />
          </div>
          <div>
            <img src={Meenakshi} className="w-100 team_img" />
          </div>
          <div>
            <img src={Manan} className="w-100 team_img" />
          </div>
        </div>
      </section> */}

<section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
        <Card extraClasses="p-120px mx-auto p-relative px-sm-30 py-sm-2rem">
          <div className="heading_left_horizontal bg-primary" style={{width: 69+'px'}}></div>
          <h2 className="mb-3 mt-sm-60px">{Data["AboutUsdata"][4]}</h2>
          <p className="w-80">
          {Data["AboutUsdata"][5]}
          </p>
        </Card>
      </section>

      <section className="section_bottom_margin max-w-1600px">
        <Card extraClasses="px-sm-30 py-sm-2rem p-100">
          <div className="d-grid-33-66">
            <div className="w-50 my-auto w-sm-100">
              <h2 className="mb-3 text-sm-center"> What our client says</h2>
              <p className="fw-500 text-sm-center mb-sm-3">Words of love from our clients</p>
            </div>
            <Card>
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
              >
                <SwiperSlide>
                  <Testimonial
                    client_img={<Ankur />}
                    client_name={Data["client"][4]}
                    // designation="ABC at somersault"
                    content={Data["client"][0]}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Testimonial
                    client_img={<Anubhav />}
                    client_name={Data["client"][5]}
                    // designation="ABC at somersault"
                    content={Data["client"][1]}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Testimonial
                    client_img={<Somya />}
                    client_name={Data["client"][6]}
                    // designation="ABC at somersault"
                    content={Data["client"][2]}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Testimonial
                    client_img={<Adarsh />}
                    client_name={Data["client"][7]}
                    // designation="ABC at somersault"
                    content={Data["client"][3]}
                  />
                </SwiperSlide>
              </Swiper>
            </Card>
          </div>
        </Card>
      </section>
     
      <section className="section_bottom_margin max-w-1600px">
        <Card extraClasses="p-120px mx-auto bg-primary px-sm-30 py-sm-2rem">
          <h2 className="text-center mb-3 text-white">{Data["AboutUsdata"][6]}</h2>
          <p className="text-white text-center w-50 mx-auto w-sm-100">
          {Data["AboutUsdata"][7]}
          </p>
        </Card>
      </section>

      <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
        <div className="d-flex d-sm-block">
          <div className="section_2-3 mr-30 mr-sm-0 mb-sm-3">
            <Card extraClasses="p-120px p-relative px-sm-30 py-sm-2rem">
          <div className="heading_left_horizontal bg-primary" style={{width: 69+'px'}}></div>

              <h2 className="mb-3 fw-700 mt-sm-60px">{Data["AboutUsdata"][8]}</h2>
              <p className="w-80">
              {Data["AboutUsdata"][9]}
              </p>
            </Card>
          </div>
          <div className="section_1-3">
            <Card extraClasses="p-120px px-sm-30 py-sm-2rem">
              <p className="m-auto about_us_our_missione_left">
              {Data["AboutUsdata"][10]}
              </p>
            </Card>
          </div>
        </div>
      </section>

      {/* <section className="section_bottom_margin max-w-1600px">
        <h2 className="text-center  ">We are happy to see you here!</h2>
        <h2 className="text-center ">
          While you might come to us for the love of reducing operational costs,
        </h2>
        <h2 className="text-center">
          we know that you and your people will grow and thrive with love from
          ours.
        </h2>
      </section> */}
      <section className="section_bottom_margin ml-30 mr-30">
          <div className="text-center ">
            <div className="section1-2 mr-30 ">
               <p><i className="texti"  style={{marginLeft:"100px",marginRight:"100px"}}>{Data["AboutUsdata"][11]}</i></p>
            </div>
            
          </div>
        </section>
    </Fragment>
  );
};

export default AboutUs;
