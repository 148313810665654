import React, { Fragment } from "react";
import PricingCard from "../components/pricing_card/PricingCard";
import Button from "../components/UI/Button";
import Helmet from 'react-helmet';
import Data from "../assets/data.json";
import FaqLanding from "../components/faq_landing/FaqLanding";
import Card from "../components/UI/Card";

const FAQs = () => {
return (
    <Fragment>
    <Helmet>
        <title>
        {Data["FAQs"][0]}
        </title>
        <meta
        name="description"
        content={Data["metadata"][15]}
        />
    </Helmet>
    <section className="bg-white pricing_banner_section policy">
        <div className="princing_banner_text text-center">
            <h1>
            FAQs
            </h1>
            {/* <p>{Data["TermsofService"][1]}</p> */}
        </div>  
    </section>
    <section className="section_bottom_margin justify-content-center align-items-stretch p-0px" style={{padding:"100px"}}>
    <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
        {/* <Card > */}
        {/* <h2 className="text-center mb-2"> FAQ’S</h2>
        <p className="text-center w-50 mb-5 m-auto">
        If we still haven't answered your queries, <br></br> get in touch at <a className="text-primary" href="tel:+918700983019">+91 8700983019</a>
        </p> */}
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][1]}
            detials={Data["FAQs"][2]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][3]}
            detials={Data["FAQs"][4]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][5]}
            detials={Data["FAQs"][6]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][7]}
            detials={Data["FAQs"][8]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][9]}
            detials={Data["FAQs"][10]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][11]}
            detials={Data["FAQs"][12]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][13]}
            detials={Data["FAQs"][14]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][15]}
            detials={Data["FAQs"][16]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][17]}
            detials={Data["FAQs"][18]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["FAQs"][19]}
            detials={Data["FAQs"][20]}
        />
        {/* </Card> */}
    </section>
    </section>
    </Fragment>
);
};

export default FAQs;
