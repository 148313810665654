import React, { Fragment } from "react";
import classes from "./Footer.module.css";
import { GrAppleAppStore } from "react-icons/gr";
import { FaGooglePlay, FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import Button from "../UI/Button";
import { Link } from "react-router-dom";
import Data from "../../assets/data.json";

const Footer = () => {
  var googlePlayClasses = classes.btn_footer + " ml-3";
  return (
    <Fragment>
      <section className={classes.request_demo}>
        <p className={classes.request_demo_text}>{Data["footerParagraph"][0]}
        </p>
        
        <Button title= {Data["footerParagraph"][1]}  link="/contact-us"/>
      </section>
      <footer className={classes.main_footer }>
        {/* <p className={classes.footer_top__text}>
           is designed for your organisation, for your people and everything
          in between!
        </p> */}
        {/* <div className="text-center mt-5 mb-5">
          <a
            className={classes.btn_footer}
            href=""
          >
            <GrAppleAppStore /> AppleStore
          </a>
          <a
            className={googlePlayClasses}
            href=""
          >
            <FaGooglePlay /> PlayStore
          </a>
        </div> */}
        <div className={classes.footer_links + ' max-w-1600px'}>
          <div>
            <h3 className={classes.footer_heading}>{Data["footerli"][0][0]}</h3>
            <ul className={classes.footer_ul}>
              <li>
                <Link to="/webdevelopment">{Data["footerli"][0][1]}</Link>
              </li>
              <li>
                <Link to="/appdevelopment">{Data["footerli"][0][2]}</Link>
              </li>
              <li>
                <Link to="/softwaresolutions">{Data["footerli"][0][3]}</Link>
              </li>
              <li>
                <Link to="/brandingstrategies">{Data["footerli"][0][4]}</Link>
              </li>
              <li>
                <Link to="/itservices">{Data["footerli"][0][5]}</Link>
              </li>
              <li>
                <Link to="/sco">{Data["footerli"][0][6]}</Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className={classes.footer_heading}>{Data["footerli"][4][0]}</h3>
            <ul className={classes.footer_ul}>
              <li>
                <Link to="/about-us">{Data["footerli"][4][1]}</Link>
              </li>
              <li>
                <Link to="/customers">{Data["footerli"][4][2]}</Link>
              </li>
              <li>
                <Link to="/careers">{Data["footerli"][4][3]}</Link>
              </li>
              <li>
                <Link to="/about-us">{Data["footerli"][4][4]}</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className={classes.footer_heading}>{Data["footerli"][1][0]}</h3>
            <ul className={classes.footer_ul}>
            <li><Link to="/blogs">{Data["footerli"][1][1]}</Link></li>
            <li><Link to="/faqs">{Data["footerli"][1][2]}</Link></li>
            <li><Link to="/gallary">{Data["footerli"][1][3]}</Link></li>
            </ul>
          </div>
          <div>
            <h3 className={classes.footer_heading}>{Data["footerli"][2][0]}</h3>
            <ul className={classes.footer_ul}>
            <li><Link to="/contact-us">{Data["footerli"][2][1]}</Link></li>
            {/* <li><Link to="/pricing">Partners  </Link></li> */}
            </ul>
          </div>
          <div>
            <h3 className={classes.footer_heading}>{Data["footerli"][3][0]}</h3>
            <ul className={classes.footer_ul}>
            <li><Link to="/privacypolicy">{Data["footerli"][3][1]}</Link></li>
            <li><Link to="/termsofservice">{Data["footerli"][3][2]}</Link></li>
            <li><Link to="/refundpolicy">{Data["footerli"][3][3]}</Link></li>
            <li><Link to="/cancellationpolicy">{Data["footerli"][3][4]}</Link></li>
            </ul>
          </div>
          
        </div>
      </footer>
      <section className={classes.copyright + ' text-center'}>
        <div>© 2020 Venkat Technologies</div>
        <div className={classes.footer_icons}>
          <Link to="https://www.facebook.com/people/Venkat-Technologies/100090272169247/" title="Visit our Facebook Page"
          aria-label="Facebook" ><FaFacebookSquare /></Link>
          <Link to="" title="Visit our Linkedin Page"
          aria-label="Linkedin" ><FaLinkedin /></Link>
        </div>
        <div>All rights reserved</div>
      </section>
    </Fragment>
  );
};

export default Footer;
