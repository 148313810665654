import React, { Fragment } from 'react'
import Button from '../UI/Button'
import Card from '../UI/Card'
import classes from './BlogCard.module.css'
import { FaArrowRight } from "react-icons/fa";
const BlogCard = (props) => {
  return (
    <Fragment>
        <Card extraClasses={classes.blog_div}>
            <div className={classes.blog_img_div} style={{background: `url(${props.url})`}}>
                <div className={classes.blog_label}>{props.badge}</div>
            </div>
            <div className={classes.blog_text}>
                <h3 className='mb-3'>{props.title}</h3>
                <p className='mb-5'>{props.description}</p>
                <p><span className='text-primary'>{props.read} Read</span> <a className={classes.blog_link}><FaArrowRight/></a></p>
            </div>
        </Card>
    </Fragment>
  )
}

export default BlogCard