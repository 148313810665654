import React from "react";
import Button from "../UI/Button";
import Card from "../UI/Card";
import Classes from "./PricingCard.module.css";
import  {RiCheckLine} from 'react-icons/ri'
const PricingCard = (props) => {
  return (
    <Card extraClasses={ props.extraClasses?props.extraClasses + ' h-auto ' + Classes.pricingCard  : Classes.pricingCard + ' h-auto '}>
        {props.badge ? <span className={Classes.pricing_badge}>{props.badge}</span> : ''}
      <h2 className="mb-3">{props.title}</h2>

      <h3 className="mb-2"> {props.pricing}</h3>
      <p className="mb-3">per month, per employee</p>
      <p className="mb-3">{props.description}</p>

      <Button type="primary" title={props.buttonTitle} onClick={props.onClick} />

      <ul className="mb-3">
        <h4 className="mb-3"> {props.feature_heading}</h4>
        {props.features.map(item=>{
            return <li> <RiCheckLine className="text-primary"/>  <span> {item} </span></li>
        })}
      </ul>
    </Card>
  );
};

export default PricingCard;
