import React from "react";
import classes from "./Testimonial.module.css";
import { AiOutlineStar } from "react-icons/ai";
const Testimonial = (props) => {
  return (
    <div className={classes.single_testimonial}>
      <p className={classes.testimonial_text}>{props.content} "</p>
      <div className={classes.testimonial_client}>
        <img
          className={classes.testimonial_client_img}
          src={props.client_img.type}
          alt="client_logo"
        />
        <div className={classes.client_details}>
          <div>
            <AiOutlineStar className="color-yellow"/>
            <AiOutlineStar className="color-yellow"/>
            <AiOutlineStar className="color-yellow"/>
            <AiOutlineStar className="color-yellow"/>
            <AiOutlineStar className="color-yellow"/>
          </div>
          <div className={classes.client_name}> {props.client_name}</div>
          <div className={classes.designation}> {props.designation}</div>
          
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
