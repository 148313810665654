import React, { Fragment } from "react";
import PricingCard from "../components/pricing_card/PricingCard";
import Button from "../components/UI/Button";
import Helmet from 'react-helmet';
import Data from "../assets/data.json";
import FaqLanding from "../components/faq_landing/FaqLanding";
import Card from "../components/UI/Card";

const TermsofService = () => {
    return (
        <Fragment>
            <Helmet>
                <title>
                    {Data["TermsofService"][0]}
                </title>
                <meta
                    name="description"
                    content={Data["metadata"][33]}
                />
            </Helmet>
            <section className="bg-white pricing_banner_section policy">
                <div className="princing_banner_text text-center">
                    <h1 className="fontsizeh1">

                        {Data["TermsofService"][0]}
                    </h1>
                    {/* <p>{Data["TermsofService"][1]}</p> */}
                </div>
            </section>
            <section className="section_bottom_margin justify-content-center align-items-stretch p-0px"  style={{ padding: "100px" }}>
                <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
                    {/* <Card > */}
                    {/* <h2 className="text-center mb-2"> FAQ’S</h2>
        <p className="text-center w-50 mb-5 m-auto">
        If we still haven't answered your queries, <br></br> get in touch at <a className="text-primary" href="tel:+918700983019">+91 8700983019</a>
        </p> */}

                    <h4 className="text-primary mb-3">{Data["TermsofService"][2]}</h4>
                    <p className="mb-3">{Data["TermsofService"][3]}</p>
                    <h4 className="text-primary mb-3">{Data["TermsofService"][4]}</h4>
                    <p className="mb-3">{Data["TermsofService"][5]}</p>
                    <h4 className="text-primary mb-3">{Data["TermsofService"][6]}</h4>
                    <p className="mb-3">{Data["TermsofService"][7]}</p>
                    <h4 className="text-primary mb-3">{Data["TermsofService"][8]}</h4>
                    <p className="mb-3">{Data["TermsofService"][9]}</p>
                    <h4 className="text-primary mb-3">{Data["TermsofService"][10]}</h4>
                    <p className="mb-3">{Data["TermsofService"][11]}</p>
                    <h4 className="text-primary mb-3">{Data["TermsofService"][12]}</h4>
                    <p className="mb-3">{Data["TermsofService"][13]}</p>
                    <h4 className="text-primary mb-3">{Data["TermsofService"][14]}</h4>
                    <p className="mb-3">{Data["TermsofService"][15]}</p>
                    <h4 className="text-primary mb-3">{Data["TermsofService"][16]}</h4>
                    <p className="mb-3">{Data["TermsofService"][17]}</p>
                    <h4 className="text-primary mb-3">{Data["TermsofService"][18]}</h4>
                    <p className="mb-3">{Data["TermsofService"][19]}</p>
                    <h4 className="text-primary mb-3">{Data["TermsofService"][20]}</h4>
                    <p className="mb-3">{Data["TermsofService"][21]}</p>

                    {/* <FaqLanding
                        extraClasses="border-bottom-1 mb-5"
                        title={Data["TermsofService"][2]}
                        detials={Data["TermsofService"][3]}
                    />
                    <FaqLanding
                        extraClasses="border-bottom-1 mb-5"
                        title={Data["TermsofService"][4]}
                        detials={Data["TermsofService"][5]}
                    />
                    <FaqLanding
                        extraClasses="border-bottom-1 mb-5"
                        title={Data["TermsofService"][6]}
                        detials={Data["TermsofService"][7]}
                    />
                    <FaqLanding
                        extraClasses="border-bottom-1 mb-5"
                        title={Data["TermsofService"][8]}
                        detials={Data["TermsofService"][9]}
                    />
                    <FaqLanding
                        extraClasses="border-bottom-1 mb-5"
                        title={Data["TermsofService"][10]}
                        detials={Data["TermsofService"][11]}
                    />
                    <FaqLanding
                        extraClasses="border-bottom-1 mb-5"
                        title={Data["TermsofService"][12]}
                        detials={Data["TermsofService"][13]}
                    />
                    <FaqLanding
                        extraClasses="border-bottom-1 mb-5"
                        title={Data["TermsofService"][14]}
                        detials={Data["TermsofService"][15]}
                    />
                    <FaqLanding
                        extraClasses="border-bottom-1 mb-5"
                        title={Data["TermsofService"][16]}
                        detials={Data["TermsofService"][17]}
                    />
                    <FaqLanding
                        extraClasses="border-bottom-1 mb-5"
                        title={Data["TermsofService"][18]}
                        detials={Data["TermsofService"][19]}
                    />
                    <FaqLanding
                        extraClasses="border-bottom-1 mb-5"
                        title={Data["TermsofService"][20]}
                        detials={Data["TermsofService"][21]}
                    /> */}
                    {/* </Card> */}
                </section>
            </section>
        </Fragment>
    );
};

export default TermsofService;
