import React, { Fragment } from "react";
import Helmet from 'react-helmet';
import Newsletter from "../components/newsletter/Newsletter";
import BlogCard from "../components/blog_card/BlogCard";
import BlogCategories from "../components/blog_categories/BlogCategories";
import Data from "../assets/data.json";
import { blog1,blog2,blog3,blog4,blog5,blog6 } from "../assets/images";
import { Link } from "react-router-dom";

const Blog = () => {
  return (
    <Fragment>
      <Helmet>
        <title>
        {Data["Blogdata"][0]}
        </title>
        <meta
        name="description"
        content={Data["Blogdata"][0]}
        />
    </Helmet>
      <section className=" bg-primary blog_page_banner ">
        <div>
          <h1 className="mb-5">{Data["Blogdata"][0]}</h1>
          <p>
        {/* {console.log(Data["Blogdata"][0])} */}
            {/* In 2019, the HRTech market in India hadn't evolved as fast as
            consumer apps and systems. Nikhil, a globally travelled creative
            business founder felt this gap when he */}
          </p>
        </div>
      </section>
      {/* <Newsletter /> */}
      {/* <BlogCategories /> */}
      <section className="blog-grid mr-30 ml-30 section_bottom_margin mt-sm-60px d-sm-block mb-sm-0" style={{marginTop:"50px"}}>  
        <Link to="/blogs/Foundation">
        <BlogCard
          url={blog1}
          badge="Featured"
          title="Foundation"
          description="Foundation is another popular framework known for its flexibility and customization options. It provides a responsive grid system, UI components, and a plethora of design elements, allowing developers to create unique and adaptable web layouts."
          read="17 Mins"
        />
        </Link>
        <Link to="/blogs/UIKit">
        <BlogCard
          url={blog2}
          badge="Most Viewed"
          title="UIKit 3"
          description="UIKit 3 is a lightweight and modular front-end framework that focuses on mobile-first design. With a wide range of components and an emphasis on responsiveness, it provides an efficient way to build modern and adaptable websites."
          read="17 Mins"
        />
        </Link>
        <Link to="/blogs/Material-UI">
        <BlogCard
          url={blog3}
          badge="Featured"
          title="Material-UI"
          description="Material-UI is a popular React UI framework that implements Google's Material Design. It offers a rich set of components and integrates seamlessly with React, enabling developers to create responsive and interactive web applications."
          read="17 Mins"
        />
        </Link>
        <Link to="/blogs/Evolution">
        <BlogCard
          url={blog4}
          badge="Featured"
          title="The Evolution of Mobile App Development: Past, Present, and Future Trends"
          description="Mobile app development has traversed a fascinating journey, evolving significantly from its inception to the current landscape and offering a glimpse into the promising future of the industry. Understanding this progression can shed light on the trends, challenges, and innovations that have shaped the way we use and develop mobile applications."
          read="17 Mins"
        />
        </Link>
        <Link to="/blogs/Fundamentals">
        <BlogCard
          url={blog5}
          badge="Featured"
          title="The Fundamentals of SEO: A Beginner's Guide"
          description="Search Engine Optimization (SEO) is a pivotal aspect of digital marketing, playing a crucial role in enhancing a website's visibility on search engine results pages. For beginners entering the realm of SEO, understanding the fundamentals is key to developing a strong foundation. Let's delve into the core principles and practices that form the bedrock of effective SEO strategies."
          read="17 Mins"
        />
        </Link>
        <Link to="/blogs/SoftwareDevelopment">
        <BlogCard
          url={blog6}
          badge="Featured"
          title="Custom Software Development: Tailoring Solutions for Unique Business Needs"
          description="In the dynamic landscape of modern businesses, off-the-shelf software may not always fulfill the unique and intricate requirements of every organization. This is where custom software development steps in, offering tailored solutions designed to address specific business needs. Let's explore the intricacies and advantages of custom software in meeting the distinctive demands of enterprises."
          read="17 Mins"
        />
        </Link>
        {/* <BlogCard
          url="https://cdn.pixabay.com/photo/2017/08/30/01/05/milky-way-2695569_960_720.jpg"
          badge="Featured"
          title="Employee Engagement in a mordern dynamic ever growing workspace dynamic ever growing workspace"
          description="You are just back from a big meeting The company has figured out what it needs to get bigger Hire enough "
          read="17 Mins"
        />
        <BlogCard
          url="https://cdn.pixabay.com/photo/2017/08/30/01/05/milky-way-2695569_960_720.jpg"
          badge="Featured"
          title="Employee Engagement in a mordern dynamic ever growing workspace dynamic ever growing workspace"
          description="You are just back from a big meeting The company has figured out what it needs to get bigger Hire enough "
          read="17 Mins"
        />
        <BlogCard
          url="https://cdn.pixabay.com/photo/2017/08/30/01/05/milky-way-2695569_960_720.jpg"
          badge="Featured"
          title="Employee Engagement in a mordern dynamic ever growing workspace dynamic ever growing workspace"
          description="You are just back from a big meeting The company has figured out what it needs to get bigger Hire enough "
          read="17 Mins"
        />
        <BlogCard
          url="https://cdn.pixabay.com/photo/2017/08/30/01/05/milky-way-2695569_960_720.jpg"
          badge="Featured"
          title="Employee Engagement in a mordern dynamic ever growing workspace dynamic ever growing workspace"
          description="You are just back from a big meeting The company has figured out what it needs to get bigger Hire enough "
          read="17 Mins"
        />
        <BlogCard
          url="https://cdn.pixabay.com/photo/2017/08/30/01/05/milky-way-2695569_960_720.jpg"
          badge="Featured"
          title="Employee Engagement in a mordern dynamic ever growing workspace dynamic ever growing workspace"
          description="You are just back from a big meeting The company has figured out what it needs to get bigger Hire enough "
          read="17 Mins"
        />
        <BlogCard
          url="https://cdn.pixabay.com/photo/2017/08/30/01/05/milky-way-2695569_960_720.jpg"
          badge="Featured"
          title="Employee Engagement in a mordern dynamic ever growing workspace dynamic ever growing workspace"
          description="You are just back from a big meeting The company has figured out what it needs to get bigger Hire enough "
          read="17 Mins"
        />
        <BlogCard
          url="https://cdn.pixabay.com/photo/2017/08/30/01/05/milky-way-2695569_960_720.jpg"
          badge="Featured"
          title="Employee Engagement in a mordern dynamic ever growing workspace dynamic ever growing workspace"
          description="You are just back from a big meeting The company has figured out what it needs to get bigger Hire enough "
          read="17 Mins"
        />
        <BlogCard
          url="https://cdn.pixabay.com/photo/2017/08/30/01/05/milky-way-2695569_960_720.jpg"
          title="Employee Engagement in a mordern dynamic ever growing workspace dynamic ever growing workspace"
          description="You are just back from a big meeting The company has figured out what it needs to get bigger Hire enough "
          read="17 Mins"
        /> */}
      </section>
    </Fragment>
  );
};

export default Blog;
