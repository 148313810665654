import React, { Fragment } from "react";
import PricingCard from "../components/pricing_card/PricingCard";
import Button from "../components/UI/Button";
import Helmet from 'react-helmet';
import Data from "../assets/data.json";
import FaqLanding from "../components/faq_landing/FaqLanding";
import Card from "../components/UI/Card";

const CancellationPolicy = () => {
return (
    <Fragment>
    <Helmet>
        <title>
        {Data["CancellationPolicy"][0]}
        </title>
        <meta
        name="description"
        content={Data["metadata"][7]}
        />
    </Helmet>
    <section className="bg-white pricing_banner_section policy">
        <div className="princing_banner_text text-center">
            <h1 className="fontsizeh1">
               {/* {console.log(Data["CancellationPolicy"])} */}
                {Data["CancellationPolicy"][0]}
            </h1>
            {/* <p>{Data["TermsofService"][1]}</p> */}
        </div>  
    </section>
    <section className="section_bottom_margin justify-content-center align-items-stretch p-0px" style={{padding:"100px"}}>
    <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
        {/* <Card > */}
        {/* <h2 className="text-center mb-2"> FAQ’S</h2>
        <p className="text-center w-50 mb-5 m-auto">
        If we still haven't answered your queries, <br></br> get in touch at <a className="text-primary" href="tel:+918700983019">+91 8700983019</a>
        </p> */}

<h4 className="text-primary mb-3">{Data["CancellationPolicy"][3]}</h4>
                <p className="mb-3">{Data["CancellationPolicy"][4]}</p>
                <h4 className="text-primary mb-3">{Data["CancellationPolicy"][5]}</h4>
                <p className="mb-3">{Data["CancellationPolicy"][6]}</p>
                <h4 className="text-primary mb-3">{Data["CancellationPolicy"][7]}</h4>
                <p className="mb-3">{Data["CancellationPolicy"][8]}</p>
                <h4 className="text-primary mb-3">{Data["CancellationPolicy"][9]}</h4>
                <p className="mb-3">{Data["CancellationPolicy"][10]}</p>
                <h4 className="text-primary mb-3">{Data["CancellationPolicy"][11]}</h4>
                <p className="mb-3">{Data["CancellationPolicy"][12]}</p>
                <h4 className="text-primary mb-3">{Data["CancellationPolicy"][13]}</h4>
                <p className="mb-3">{Data["CancellationPolicy"][14]}</p>
                <h4 className="text-primary mb-3">{Data["CancellationPolicy"][15]}</h4>
                <p className="mb-3">{Data["CancellationPolicy"][16]}</p>
                <h4 className="text-primary mb-3">{Data["CancellationPolicy"][17]}</h4>
                <p className="mb-2">{Data["CancellationPolicy"][18]}</p>
                <p className="mb-3">{Data["CancellationPolicy"][19]}</p>
        {/* <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["CancellationPolicy"][3]}
            detials={Data["CancellationPolicy"][4]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["CancellationPolicy"][5]}
            detials={Data["CancellationPolicy"][6]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["CancellationPolicy"][7]}
            detials={Data["CancellationPolicy"][8]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["CancellationPolicy"][9]}
            detials={Data["CancellationPolicy"][10]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["CancellationPolicy"][11]}
            detials={Data["CancellationPolicy"][12]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["CancellationPolicy"][13]}
            detials={Data["CancellationPolicy"][14]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["CancellationPolicy"][15]}
            detials={Data["CancellationPolicy"][16]}
        />
        <FaqLanding
            extraClasses="border-bottom-1 mb-5"
            title={Data["CancellationPolicy"][17]}
            detials={Data["CancellationPolicy"][18]}
        /> */}
        {/* </Card> */}
    </section>
    </section>
    </Fragment>
);
};

export default CancellationPolicy;
