import React, { Fragment } from "react";
import PricingCard from "../components/pricing_card/PricingCard";
import Button from "../components/UI/Button";
import Helmet from 'react-helmet';
import Data from "../assets/data.json";
import FaqLanding from "../components/faq_landing/FaqLanding";
import Card from "../components/UI/Card";

const Careers = () => {
return (
    <Fragment>
    <Helmet>
        <title>
        {Data["gallarydata"][0]}
        </title>
        <meta
        name="description"
        content={Data["metadata"][9]}
        />
    </Helmet>
    <section className="bg-white pricing_banner_section">
        <div className="princing_banner_text text-center">
            <h1>
                {Data["gallarydata"][0]}
            </h1>
            {/* <p>{Data["TermsofService"][1]}</p> */}
        </div>  
    </section>
   
    </Fragment>
);
};

export default Careers;
