import React, { Fragment } from "react";
import LandingBanner from "../components/landing_banner/LandingBanner";
import Card from "../components/UI/Card";
import WorkCompanion from "../components/work_companion/WorkCompanion";
import Helmet from 'react-helmet';
import Data from "../assets/data.json";
import {Branding1, Branding2, Branding3} from "../assets/images"

const BrandingStrategies = () => {
  return (
    <Fragment>
      <Helmet>
        <title>{Data["BrandingStrategiesdata"][0]}</title>
        <meta
          name="description"
          content={Data["metadata"][5]}
        />
      </Helmet>
      <LandingBanner
        title={Data["BrandingStrategiesdata"][0]}
        textBold=""
        text={Data["BrandingStrategiesdata"][2]}
        class="color-pink section_bottom_margin"
        cta_title={Data["footerParagraph"][1]}
        cta_link="www.google.com"
        type="primary"
        headingClass="color-pink"
      />

      <section className="section_bottom_margin first_section ml-30 mr-30 max-w-1600px d-sm-block">
        <div className="section_2-3 mr-30 mr-sm-0 p-sm-0">
          <Card>
            <div className="first_section_text_div">
            <div className="heading_left_horizontal bg-pink"></div>

              <h2 className="color-pink mb-3 mt-sm-60px">
              {Data["BrandingStrategiesdata"][3]}
              </h2>
              <p className="mb-2">
              {Data["BrandingStrategiesdata"][4]}
              </p>
            </div>
          </Card>
        </div>
        <div className="section_1-3 ">
          <Card>
            <div className="first_section_illustration_div">
              <img className="img-center w-30" src={Branding1} />
            </div>
          </Card>
        </div>
      </section>

      <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
        <Card>
          <div className="d-flex d-sm-block px-sm-30 py-sm-2rem">
            <div className="section1-2 p-1 mr-30 mr-sm-0">
              <Card extraClasses=" p-2">
                <img src={Branding2} className="p-2 w-90 d-block m-auto" style={{borderRadius:"30px"}}/>
              </Card>
            </div>
            <div className="section1-2 m-auto mx-50px mx-sm-0">
              <h2 className="color-pink mb-3"> {Data["BrandingStrategiesdata"][5]}</h2>
              <h4 className="mb-2">
              {Data["BrandingStrategiesdata"][6]}
              </h4>
              <p className="mb-2"><b>{Data["BrandingStrategiesdata"][7]} :</b> {Data["BrandingStrategiesdata"][8]}</p>
              <p className="mb-2"><b>{Data["BrandingStrategiesdata"][9]} :</b> {Data["BrandingStrategiesdata"][10]}</p>
              <p className="mb-2"><b>{Data["BrandingStrategiesdata"][11]} :</b> {Data["BrandingStrategiesdata"][12]}</p>
              <p className="mb-2"><b>{Data["BrandingStrategiesdata"][13]} :</b> {Data["BrandingStrategiesdata"][14]}</p>
              <p className="mb-2"><b>{Data["BrandingStrategiesdata"][15]} :</b> {Data["BrandingStrategiesdata"][16]}</p>
              <p className="mb-2"><b>{Data["BrandingStrategiesdata"][17]} :</b> {Data["BrandingStrategiesdata"][18]}</p>
            </div>
          </div>
        </Card>
      </section>
      <WorkCompanion />
      <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
        <div className="d-flex d-sm-block">
          <div className="section1-2 mr-30 mr-sm-0">
            <Card extraClasses="p-120px px-sm-30 py-sm-2rem">
              <h2 className="color-pink mb-3">{Data["BrandingStrategiesdata"][19]}</h2>
              {/* <h4 className="mb-3">We know you care! So let them know</h4> */}
              <p className="mb-2"><b>{Data["BrandingStrategiesdata"][20]} :</b> {Data["BrandingStrategiesdata"][21]}</p>
              <p className="mb-2"><b>{Data["BrandingStrategiesdata"][22]} :</b> {Data["BrandingStrategiesdata"][23]}</p>
              <p className="mb-2"><b>{Data["BrandingStrategiesdata"][24]} :</b> {Data["BrandingStrategiesdata"][25]}</p>
              <p className="mb-2"><b>{Data["BrandingStrategiesdata"][26]} :</b> {Data["BrandingStrategiesdata"][27]}</p>
              <p className="mb-2"><b>{Data["BrandingStrategiesdata"][28]} :</b> {Data["BrandingStrategiesdata"][29]}</p>
              <p className="mb-2"><b>{Data["BrandingStrategiesdata"][30]} :</b> {Data["BrandingStrategiesdata"][30]}</p>
            </Card>
          </div>

          <div className="section1-2">
            <Card>
              <img src={Branding3} className="p-2 w-50 img-center" />
            </Card>
          </div>
        </div>
      </section>
      <section className="section_bottom_margin ml-30 mr-30">
          <div className="text-center ">
            <div className="section1-2 mr-30 ">
               <p><i className="texti" style={{marginLeft:"100px",marginRight:"100px"}}>{Data["BrandingStrategiesdata"][51]}</i></p>
            </div>
            
          </div>
        </section>
    </Fragment>
  );
};

export default BrandingStrategies;
