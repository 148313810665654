import React, { Fragment, useState, useRef } from "react";
import Button from "../components/UI/Button";
import Card from "../components/UI/Card";
import ReCAPTCHA from "react-google-recaptcha";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import Helmet from 'react-helmet'
import Data from "../assets/data.json";
import * as XLSX from 'xlsx';
import { Link } from "react-router-dom";

const ContactUs = () => {
  const [contactSales, SetContactSales] = useState(1);
  const [contactPartnerShips, SetcontactPartnerShips] = useState(0);
  const [formData, setFormData] = useState({
    email: '',
    designation: '',
    number: '',
    company_name: '',
  });
  
  const captchaRef = useRef(null);

  const contactFormHandler = () => {};

  const toggleSalesFormHandler = () => {
    SetContactSales(1);
    document.getElementById("contact_sales").classList.add("bg-grey");
    document.getElementById("contact_partnerships").classList.remove("bg-grey");
    SetcontactPartnerShips(0);
    captchaRef.current.reset();
  };

  const togglePartnershipFormHandler = () => {
    SetContactSales(0);
    document.getElementById("contact_sales").classList.remove("bg-grey");
    document.getElementById("contact_partnerships").classList.add("bg-grey");
    SetcontactPartnerShips(1);
    captchaRef.current.reset();
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Check the ReCAPTCHA response
    // const captchaValue = captchaRef.current.getValue();
    // if (!captchaValue) {
    //   alert('Please complete the ReCAPTCHA verification.');
    //   return;
    // }

    // Convert form data to JSON
    const jsonData = formData;
   
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // // Add a worksheet to the workbook
    const ws = XLSX.utils.json_to_sheet([jsonData]);

    // // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // // Save the workbook as an Excel file
    XLSX.writeFile(wb, 'salesFormData.xlsx');
    window.location.reload(false);
  };
  return (
    <Fragment>
      <Helmet>
        <title>Contact Us</title>
        <meta
          name="description"
          content={Data["metadata"][11]}
        />
      </Helmet>
      <section className="contact_us_section section_bottom_margin bg-white">
        <div className="p-120px px-sm-30 py-sm-2rem">
          <Card
            extraClasses="p-50px bg-grey cursor-pointer"
            id="contact_sales"
          >
            <div>
              <h2 className="text-center text-primary mb-3">Contact Us</h2>
              {/* <p className="text-center w-80 mx-auto">
                We’ll help you find the right products & pricing for your
                business.
              </p> */}
            </div>
          </Card>
          {/* <Card extraClasses="p-120px cursor-pointer" id="contact_partnerships">
            <div onClick={togglePartnershipFormHandler}>
              <h2 className="text-center text-primary mb-3">
                Contact Partnerships
              </h2>
              <p className="text-center w-80 mx-auto">
                We’ll help you find the right products & pricing for your
                business.
              </p>
            </div>
          </Card> */}
        </div>
      </section>
      <section className="section_bottom_margin  ml-30 mr-30">
        <Card>
          {contactSales ? (
            <Card extraClasses="p-80px mb-5 px-sm-30 py-sm-2rem" id="contactSalesCard">
              <h2>Reach out to us</h2>
              <form id="contact_sales_form" onSubmit={handleFormSubmit}>
                <div className="d-grid-50-50 mb-5 mb-sm-0">
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email Address"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  <input
                    type="text"
                    name="designation"
                    placeholder="Message"
                    value={formData.designation}
          onChange={handleInputChange}
                  />
                </div>
                <div className="d-grid-50-50 mb-5 mb-sm-0">
                  <input
                    type="number"
                    name="number"
                    placeholder="Your Phone Number"
                    value={formData.number}
                    onChange={handleInputChange}
                  
                  />
                  <input
                    type="text"
                    name="company_name"
                    placeholder="Remark"
                    value={formData.company_name}
                    onChange={handleInputChange}
                  />
                </div>
                {/* <ReCAPTCHA
                  sitekey="6LcU7uQhAAAAAIbb9zGjOKNsWAzMZ5_WcdoBTmwQ"
                  ref={captchaRef}
                /> */}
                <Button type="primary" title="Submit"/>
              </form>
            </Card>
          ) : (
            ""
          )}

          {contactPartnerShips ? (
            <Card extraClasses="p-80px mb-5 px-sm-30 py-sm-2rem" id="contactPartnersCard">
              <h2>Reach out to us</h2>
              <form id="contact_partner_form" >
                <div className="d-grid-50-50 mb-5 mb-sm-0">
                <input
                    type="text"
                    name="fullName"
                    placeholder="Full Name"
                    value={formData.email}
          onChange={handleInputChange}
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email Address"
                    value={formData.designation}
                    onChange={handleInputChange}
                  />
                 
                </div>
                <div className="d-grid-50-50 mb-5 mb-sm-0">
                  <input
                    type="number"
                    name="number"
                    placeholder="Your Phone Number"
                    value={formData.number}
          onChange={handleInputChange}
                    
                  />
                  <input
                    type="text"
                    name="company_name"
                    placeholder="Company Name"
                    className="mb-0"
                    value={formData.company_name}
          onChange={handleInputChange}
                  />
                </div>
                {/* <ReCAPTCHA
                  sitekey="6LcU7uQhAAAAAIbb9zGjOKNsWAzMZ5_WcdoBTmwQ"
                  ref={captchaRef}
                /> */}
                <Button type="primary" title="Submit"/>
              </form>
            </Card>
          ) : (
            ""
          )}
           </Card>
           <Card>
          <div className="p-120px py-sm-2rem px-sm-30 mt-sm-3">
            <h2 className="mb-3">{Data["ContactUsdata"][0]}</h2>
            <div className="d-grid-33-33-33 mb-5">
              <div>
              {Data["ContactUsdata"][1]}<br></br>
              {Data["ContactUsdata"][2]}
              </div>
              <div>
              {Data["ContactUsdata"][3]} <br></br>
                <a href="tel:+918979640568">{Data["ContactUsdata"][4]} </a>
              </div>
              <div>
              {Data["ContactUsdata"][5]}<br></br>  {Data["ContactUsdata"][6]}
              </div>
            </div>
            <div className="d-flex text-bold font-size-20">
              <span className="mr-2">Follow us on</span>
              <Link to="https://www.facebook.com/people/Venkat-Technologies/100090272169247/" className="mr-2" ><FaFacebook/></Link>
              <Link to="#" className="mr-2"><FaTwitter/></Link>
              <Link to="#" className="mr-2"><FaFacebook/></Link>
              <Link to="#" className="mr-2"><FaInstagram/></Link>
            </div>
          </div>
        </Card>
      </section>
    </Fragment>
  );
};

export default ContactUs;
