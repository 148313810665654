import React from 'react'
import classes from './Button.module.css'
import { Link } from 'react-router-dom'
const Button = (props) => {
  
  return (
    <Link to={props.link}>
    <button className={props.type === 'primary' ? classes.btn_primary : classes.btn_white }  >{props.title}</button></Link>
  )
}

export default Button