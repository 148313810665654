import React, { Fragment } from "react";
import PricingCard from "../components/pricing_card/PricingCard";
import Button from "../components/UI/Button";
import Helmet from 'react-helmet';
import Data from "../assets/data.json";
import FaqLanding from "../components/faq_landing/FaqLanding";
import Card from "../components/UI/Card";
import {blog3} from "../assets/images";
const Blog3 = () => {
return (
    <Fragment>
    <Helmet>
        <title>
        {Data["blog3"][0]}
        </title>
        <meta
        name="description"
        content={Data["blog3"][1]}
        />
    </Helmet>
    <section className="bg-white pricing_banner_section policy">
                <div className="princing_banner_text text-center">
                    <h1 className="fontsizeh1">
                        {Data["blog3"][0]}
                    </h1>
                </div>
            </section>
            <section className="section_bottom_margin justify-content-center align-items-stretch p-0px" style={{ padding: "100px" }}>
                <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
                   
                   <div className="text-center" style={{marginBottom:"30px"}}>
                   <img src={blog3} className="w-50D" style={{borderRadius:"30px"}}></img>
                   </div>
                    <h4 className="text-primary mb-3">{Data["blog3"][2]}</h4>
                    <p className="mb-3">{Data["blog3"][3]}</p>
                    <h4 className="text-primary mb-3">{Data["blog3"][4]}</h4>
                    <p className="mb-3">{Data["blog3"][5]}</p>
                    <h4 className="text-primary mb-3">{Data["blog3"][6]}</h4>
                    <p className="mb-3">{Data["blog3"][7]}</p>
                    <h4 className="text-primary mb-3">{Data["blog3"][8]}</h4>
                    <p className="mb-3">{Data["blog3"][9]}</p>
                    <h4 className="text-primary mb-3">{Data["blog3"][10]}</h4>
                    <p className="mb-3">{Data["blog3"][11]}</p>
                    <h4 className="text-primary mb-3">{Data["blog3"][12]}</h4>
                    <p className="mb-3">{Data["blog3"][13]}</p>
                    <h4 className="text-primary mb-3">{Data["blog3"][14]}</h4>
                    <p className="mb-3">{Data["blog3"][15]}</p>
                    <h4 className="text-primary mb-3">{Data["blog3"][16]}</h4>
                    <p className="mb-3">{Data["blog3"][17]}</p> 
                   
                </section>
            </section>
   
    </Fragment>
);
};

export default Blog3;
