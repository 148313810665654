        import React, { Fragment } from "react";
        import PricingCard from "../components/pricing_card/PricingCard";
        import Button from "../components/UI/Button";
        import Helmet from 'react-helmet';
        import Data from "../assets/data.json";
        import FaqLanding from "../components/faq_landing/FaqLanding";
        import Card from "../components/UI/Card";
        const Privacypolicy = () => {
        return (
            <Fragment>
            <Helmet>
                <title>
                {Data["PrivacyPolicy"][0]}
                </title>
                <meta
                name="description"
                content={Data["metadata"][25]}
                />
            </Helmet>
            <section className="bg-white pricing_banner_section policy">
                <div className="princing_banner_text text-center">
                    <h1 className="fontsizeh1">
                        {Data["PrivacyPolicy"][0]}
                    </h1>
                </div>  
            </section>
            <section className="section_bottom_margin justify-content-center align-items-stretch p-0px" style={{padding:"100px"}}>
            <section className="section_bottom_margin ml-30 mr-30 max-w-1600px">
                {/* <Card > */}
                {/* <h2 className="text-center mb-2"> FAQ’S</h2>
                <p className="text-center w-50 mb-5 m-auto">
                If we still haven't answered your queries, <br></br> get in touch at <a className="text-primary" href="tel:+918700983019">+91 8700983019</a>
                </p> */}
                <h4 className="text-primary mb-3">{Data["PrivacyPolicy"][1]}</h4>
                <p className="mb-3">{Data["PrivacyPolicy"][2]}</p>
                <h4 className="text-primary mb-3">{Data["PrivacyPolicy"][3]}</h4>
                <p className="mb-3">{Data["PrivacyPolicy"][4]}</p>
                <h4 className="text-primary mb-3">{Data["PrivacyPolicy"][5]}</h4>
                <p className="mb-3">{Data["PrivacyPolicy"][6]}</p>
                <h4 className="text-primary mb-3">{Data["PrivacyPolicy"][7]}</h4>
                <p className="mb-3">{Data["PrivacyPolicy"][8]}</p>
                <h4 className="text-primary mb-3">{Data["PrivacyPolicy"][9]}</h4>
                <p className="mb-3">{Data["PrivacyPolicy"][10]}</p>
                <h4 className="text-primary mb-3">{Data["PrivacyPolicy"][11]}</h4>
                <p className="mb-3">{Data["PrivacyPolicy"][12]}</p>
                <h4 className="text-primary mb-3">{Data["PrivacyPolicy"][13]}</h4>
                <p className="mb-3">{Data["PrivacyPolicy"][14]}</p>
                <h4 className="text-primary mb-3">{Data["PrivacyPolicy"][15]}</h4>
                <p className="mb-3">{Data["PrivacyPolicy"][16]}</p>
                <h4 className="text-primary mb-3">{Data["PrivacyPolicy"][17]}</h4>
                <p className="mb-3">{Data["PrivacyPolicy"][18]}</p>
                <h4 className="text-primary mb-3">{Data["PrivacyPolicy"][19]}</h4>
                <p className="mb-3">{Data["PrivacyPolicy"][20]}</p>
                {/* <FaqLanding
                    extraClasses="border-bottom-1 mb-5"
                    title={Data["PrivacyPolicy"][1]}
                    detials={Data["PrivacyPolicy"][2]}
                />
                <FaqLanding
                    extraClasses="border-bottom-1 mb-5"
                    title={Data["PrivacyPolicy"][3]}
                    detials={Data["PrivacyPolicy"][4]}
                />
                <FaqLanding
                    extraClasses="border-bottom-1 mb-5"
                    title={Data["PrivacyPolicy"][5]}
                    detials={Data["PrivacyPolicy"][6]}
                />
                <FaqLanding
                    extraClasses="border-bottom-1 mb-5"
                    title={Data["PrivacyPolicy"][7]}
                    detials={Data["PrivacyPolicy"][8]}
                />
                <FaqLanding
                    extraClasses="border-bottom-1 mb-5"
                    title={Data["PrivacyPolicy"][9]}
                    detials={Data["PrivacyPolicy"][10]}
                />
                <FaqLanding
                    extraClasses="border-bottom-1 mb-5"
                    title={Data["PrivacyPolicy"][11]}
                    detials={Data["PrivacyPolicy"][12]}
                />
                <FaqLanding
                    extraClasses="border-bottom-1 mb-5"
                    title={Data["PrivacyPolicy"][13]}
                    detials={Data["PrivacyPolicy"][14]}
                />
                <FaqLanding
                    extraClasses="border-bottom-1 mb-5"
                    title={Data["PrivacyPolicy"][15]}
                    detials={Data["PrivacyPolicy"][16]}
                />
                <FaqLanding
                    extraClasses="border-bottom-1 mb-5"
                    title={Data["PrivacyPolicy"][17]}
                    detials={Data["PrivacyPolicy"][18]}
                />
                <FaqLanding
                    extraClasses="border-bottom-1 mb-5"
                    title={Data["PrivacyPolicy"][19]}
                    detials={Data["PrivacyPolicy"][20]}
                /> */}
                {/* </Card> */}
            </section>
            </section>
            </Fragment>
        );
        };

        export default Privacypolicy;
